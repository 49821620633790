<div class="xl:max-w-[1024px] xxl:max-w-[1366px] mr-[20px] ml-[5px] font-Mulish">
  <app-toast
    [statusToast]="statusToast"
    id="notifiListUser"
    MessToast="{{ MessToast }}"
  ></app-toast>
  <div class="mb-5 pt-10">
    <p class="text-[34px] text-textblue leading-9 font-normal">{{ componentName }}</p>
  </div>
  <div class="mb-[70px]">
    <div class="flex justify-between">
      <div>
        <input
          type="button"
          class="primary-button"
          (click)="openModalAdd()"
          value="Thêm nhân viên"
        >
      </div>
      <div>
        <div class="flex relative">
          <input
            class="hover:border-none border hover:bg-primaryblue h-10 px-5 pr-16 rounded-lg text-sm w-[400px] focus:outline-none placeholder-textblue placeholder:font-semibold"
            placeholder="Tìm kiếm"
            #searchBox
            (input)="searchTerm(searchBox.value)"
            [(ngModel)]="tem"
          >
          <img
            src="/assets/icons/search.svg"
            class="absolute right-3 top-3"
          >
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="overflow-x-auto">
      <table class="w-full">
        <thead class="text-textblue">
          <tr>
            <th
              scope="col"
              class="text-left"
            >
              Họ
            </th>
            <th
              scope="col"
              class="text-left"
            >
              Tên
            </th>
            <th
              scope="col"
              class="text-center"
            >
              Email
            </th>
            <th
              scope="col"
              class="text-center"
            >
              Bộ phận
            </th>
            <!-- <th
              scope="col"
              class="text-left"
            >
              <div class="flex justify-between">
                <p>Trạng thái</p>
                <p>Hoạt động</p>
              </div>
            </th> -->
            <th
              scope="col"
              class="text-center"
            >
              Trạng thái
            </th>
            <th
              scope="col"
              class="text-center"
            >
              Thao tác
            </th>
          </tr>
        </thead>
        <tbody></tbody>
        <tbody
          *ngFor="let user of data"
          class="text-xs leading-6 font-semibold hover:bg-primarygray hover:text-textblue"
        >
          <tr class="border-primarygray border-t-2 py-20 border-separate">
            <td
              scope="col"
              class="py-4 text-left"
              (click)="selectUser(componentsId ? user.user.id : user.id)"
            >
              {{ componentsId ? user.user.lastName : user.lastName }}
            </td>
            <td
              scope="col"
              class="py-4 text-left"
              (click)="selectUser(componentsId ? user.user.id : user.id)"
            >
              {{ componentsId ? user.user.firstName : user.firstName }}
            </td>
            <td
              scope="col"
              class="py-4 text-left"
              (click)="selectUser(componentsId ? user.user.id : user.id)"
            >
              {{ componentsId ? user.user.email : user.email }}
            </td>
            <td
              scope="col"
              class="py-4 text-center"
              (click)="selectUser(componentsId ? user.user.id : user.id)"
            >
              {{ componentsId ? user.componentName : user.role }}
            </td>
            <td
              scope="col"
              class="py-4 text-center"
            >
              <span
                (click)="selectUser(componentsId ? user.user.id : user.id)"
                class="{{
                  componentsId
                    ? user.user.enabled
                      ? 'active'
                      : 'deactive'
                    : user.enabled
                    ? 'active'
                    : 'deactive'
                }}"
              >
                {{
                componentsId
                ? user.user.enabled
                ? 'Kích hoạt'
                : 'Vô hiệu hóa'
                : user.enabled
                ? 'Kích hoạt'
                : 'Vô hiệu hóa'
                }}
              </span>
            </td>


            <td class="flex-col items-center">
              <img
                class="cursor-pointer mx-auto"
                src="/assets/icons/trash.svg"
                (click)="
                  openModal('delete', user.lastName, user.firstName, componentsId ? user.user.id : user.id)
                "
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="relative mt-[45px] mb-[100px]">
      <div class="flex gap-4 absolute right-0 bottom-0 mt-[30px] mr-1">
        <div
          class="flex gap-2 {{
            currentPage === 0 ? 'pointer-events-none' : 'cursor-pointer'
          }} "
          (click)="onPagingButtonClicked(currentPage)"
        >
          <img
            src="/assets/icons/arrow left.svg"
            class="select-none"
          >
          <p class="select-none mt-1">Trước</p>
        </div>
        <div *ngFor="let page of getListPage(true)">
          <div
            class="cursor-pointer text-center w-8 h-8 px-1 py-1 rounded
            {{
              currentPage + 1 === isNumber(page)
                ? 'bg-bluepage text-white'
                : 'bg-whitef'
            }} "
            (click)="onPagingButtonClicked(page)"
          >
            {{ page }}
          </div>
        </div>
        <div
          class="flex gap-2 {{
            currentPage === totalPage - 1
              ? 'pointer-events-none'
              : 'cursor-pointer'
          }} "
          (click)="onPagingButtonClicked(currentPage + 2)"
        >
          <img
            src="/assets/icons/arrow right.svg"
            class="select-none"
          >
          <p class="select-none mt-1">Sau</p>
        </div>
        <select
          (change)="changesize($event)"
          class="ml-[30px] outline-none bg-whitef hidden"
        >
          <option value="10">10 per page</option>
          <option value="15">15 per page</option>
          <option value="20">20 per page</option>
        </select>
      </div>
    </div>
  </div>
</div>
<app-modal id="delete">
  <div class="pt-[30px] w-[450px] pb-7 text-center m-auto font-Mulish">
    <div class="flex justify-between px-7 mb-2">
      <span class="text-redtextremove font-Mulish text-2xl">Xóa tài khoản</span>
      <img
        class="h-5 w-5 cursor-pointer"
        src="/assets/icons/close.svg"
        (click)="closeModal('delete')"
      >
    </div>
    <p class="border border-linegray"></p>
    <div class="px-7 mb-2 flex-col text-left font-semibold">
      <p class="mt-[20px]">
        Bạn có chắc chắn muốn xóa tài khoản {{ lastname }} {{ firstname }} khỏi
        bộ phận này ?
      </p>
    </div>


    <div class="pt-6 px-7 mb-2 flex justify-between font-semibold">
      <input
        class="bg-whitef text-buttongray py-[4px] px-[24px] w-[140px] rounded-lg border border-buttongray cursor-pointer"
        type="button"
        value="Hủy"
        (click)="closeModal('delete')"
      >
      <input
        class="bg-redbuttonremove text-redtextremove py-[4px] px-[24px] w-[140px] rounded-lg border border-buttongray cursor-pointer"
        type="button"
        value="Vô hiệu hóa"
        (click)="deleteUser()"
      >
    </div>
  </div>
</app-modal>
<!-- <app-modal
  id="createUser"
  [formGroup]="form"
  #formDirective="ngForm" 
>
  <div class="w-[800px] h-[690px] py-[47px] px-[32px]">
    <div class="text-textblue text-[24px] leading-6 font-medium  flex justify-between">
      <p>Thêm mới nhân viên</p>
      <img
        src="/assets/icons/close.svg"
        class="w-4 h-4 cursor-pointer"
        (click)="closeModal('createUser')"
      >
    </div>
    
    <div class="mt-6 flex">
      <div class="">
        <div>
          <app-primary-input
            title="Họ"
            placeholder="Họ"
            formControlName="lastname"
            [formFieldControl]="fields.lastname"
            [errrorMessages]="FieldErrors"
            [(ngModel)]="txtlastname"
            [isFixed]="true"
          ></app-primary-input>
        </div>
        <div class="mt-6">
          <app-primary-input
            [isFixed]="true"
            formControlName="email"
            [formFieldControl]="fields.email"
            [(ngModel)]="txtEmail"
            [errrorMessages]="FieldErrors"
            title="Email"
            placeholder="Email"
          ></app-primary-input>
        </div>
        <div class="mt-6">
          <app-input-date
            date="{{txtdate}}"
            (getDataEvent)="show($event)"

          ></app-input-date>
        </div>
        <div class="mt-6">
          <app-primary-input
            [isFixed]="true"
            formControlName="idpassport"
            [errrorMessages]="FieldErrors"
            [formFieldControl]="fields.idpassport"
            [(ngModel)]="txtIdpassport"
            title="Số CMND/ CCCD/ Hộ chiếu"
            placeholder="Số CMND/ CCCD/ Hộ chiếu"
          ></app-primary-input>
        </div>
        <div class="mt-6">
          <app-primary-input
            [isFixed]="true"
            formControlName="phonenumber"
            [errrorMessages]="FieldErrors"
            [formFieldControl]="fields.phonenumber"
            [(ngModel)]="txtPhoneNumber"
            title="Số điện thoại"
            placeholder="Số điện thoại"
          ></app-primary-input>
        </div>
      </div>
      
      <div class="pl-[40px]">
        <div>
          <app-primary-input
            [isFixed]="true"
            title="Tên"
            placeholder="Tên"
            [errrorMessages]="FieldErrors"
            [formFieldControl]="fields.firstname"
            formControlName="firstname"
            [(ngModel)]="txtfirstname"
          ></app-primary-input>
        </div>
        <div class="mt-6">
          <app-primary-input
            [Isdisabled]="true"
            title="ID nhân viên"
            placeholder="ID nhân viên"
          ></app-primary-input>
        </div>
        <div class="mt-6">
          <label class="text-textblue font-semibold  text-[14px] leading-5" >Giới tính</label>
          <div class="flex justify-between mt-4 text-textblue">
            <div class="flex">
              <img
                src="{{checkedSex==='male'?'/assets/icons/toggle-true.svg':'/assets/icons/toggle-false.svg'}}"
                (click)="checkSex('male')"
                class="mr-1"
              >
              <p>Nam</p>
            </div>
            <div class="flex">
              <img
                src="{{checkedSex==='female' ?'/assets/icons/toggle-true.svg':'/assets/icons/toggle-false.svg'}}"
                (click)="checkSex('female')"
                class="mr-1"
              >
              <p>Nữ</p>
            </div>
            <div class="flex">
              <img
                src="{{ checkedSex === 'other'? '/assets/icons/toggle-true.svg':'/assets/icons/toggle-false.svg'}}"
                (click)="checkSex('other')"
                class="mr-1"
              >
              <p>Khác</p>
            </div>
          </div>
        </div>
        <div class="mt-8">
          <app-primary-input
            [isFixed]="true"
            [formFieldControl]="fields.country"
            [errrorMessages]="FieldErrors"
            formControlName="country"
            [(ngModel)]="txtCountry"
            title="Quốc tịch"
            placeholder="Quốc tịch"
          ></app-primary-input>
        </div>
        <div class="mt-6">
          <app-primary-input
            [isFixed]="true"
            formControlName="address"
            [errrorMessages]="FieldErrors"
            [formFieldControl]="fields.address"
            title="Địa chỉ"
            placeholder="Địa chỉ"
          ></app-primary-input>
        </div>
      </div>
    </div>

    <div class="flex justify-end mt-6 ">
      <button
        [disabled]="form.invalid"
        class="primary-button disabled:bg-blueBADCEE disabled:text-white disabled:cursor-auto"
        (click)="addUser()"
      >
       Thêm
      </button>
    </div>
  </div>
</app-modal> -->


<app-modal id="createUser">
        <div class="w-[900px] py-[47px] px-8 justify-center">
          <div class="relative">
            <div class="pb-6 text-textblue text-[24px] leading-6 font-medium flex justify-between">
              <p>Thêm mới nhân viên</p>
              <img src="/assets/icons/close.svg" class="w-4 h-4 cursor-pointer" (click)="closeModal('createUser')">
            </div>
        
            <div class="grid md:grid-cols-2 md:gap-6">
              <div class="relative z-0 mb-6 w-[326px] group">
                <!--        <app-primary-input
                      title="Họ"
                      placeholder="Họ"
                      formControlName="lastname"
                      [formFieldControl]="fields.lastname"
                      [errrorMessages]="FieldErrors"
                      [(ngModel)]="txtlastname"
                      [isFixed]="true"
                      [Isdisabled]="isEdit"
                    ></app-primary-input> -->
                <app-prime-input title="Họ" [control]="getControls('lastName')" [isRequire]="true" [showError]="showError"  >
        
                </app-prime-input>
              </div>
        
              <div class="relative z-0 mb-6 w-[326px] group">
                <!--       <app-primary-input
                            [isFixed]="true"
                            title="Tên"
                            placeholder="Tên"
                            [errrorMessages]="FieldErrors"
                            [formFieldControl]="fields.firstname"
                            formControlName="firstname"
                            [(ngModel)]="txtFirstName"
                            [Isdisabled]="isEdit"
                          ></app-primary-input> -->
                <app-prime-input title="Tên" [control]="getControls('firstName')" [isRequire]="true" [showError]="showError"  >
        
                </app-prime-input>
              </div>
        
            </div>
        
            <div class="grid md:grid-cols-2 md:gap-6">
              <div class="relative z-0 mb-6 w-[326px] group">
                <!--      <app-primary-input
                        [isFixed]="true"
                        formControlName="email"
                        [formFieldControl]="fields.email"
                        [(ngModel)]="txtEmail"
                        [errrorMessages]="FieldErrors"
                        title="Email"
                        placeholder="Email"
                        [Isdisabled]="isEdit"
                      ></app-primary-input> -->
                <app-prime-input title="Email" [control]="getControls('email')" [isRequire]="true" [showError]="showError" >
        
                </app-prime-input>
              </div>
        
              <div class="relative z-0 mb-6 w-[326px] group">
                <!--   <app-primary-input
                        [Isdisabled]="true"
                        title="ID nhân viên"
                        placeholder="ID nhân viên"
                      ></app-primary-input> -->
                <app-prime-input title="ID nhân viên" [disabled]="true" >
        
                </app-prime-input>
              </div>
            </div>
        
            <div class="grid md:grid-cols-2 md:gap-6">
              <div class="relative z-50 mb-6 w-[326px] group">
                <app-input-date [control]="getControls('birthDate')" (getDataEvent)="show($event)">
                </app-input-date>
              </div>
        
              <div class="relative z-0 mb-6 w-[326px] group">
                <label class="  font-semibold  text-[14px] leading-5">Giới
                  tính</label>
                <div class="flex justify-between mt-4">
                  <span *ngFor="let item of genders" class="flex">
                    <img [src]="checkValueGender(item.code)" class="mx-2"
                      (click)="changeGender(item.code)">
                    <span class="cursor-default ">{{item.value}}</span>
                  </span>
                </div>
                <!--          <div
                          class="flex justify-between mt-4 text-scgray"
                          *ngIf="isEdit">
                          <div class="flex">
                            <img
                              src="{{checkedSex==='male'?'/assets/icons/toggle-check-dis.svg':'/assets/icons/toggle-none-dis.svg'}}"
                              class="mx-2"
                            >
                            <p>Nam</p>
                          </div>
                          <div class="flex">
                            <img
                              src="{{checkedSex==='female' ?'/assets/icons/toggle-check-dis.svg':'/assets/icons/toggle-none-dis.svg'}}"
                              class="mx-2"
                            >
                            <p>Nữ</p>
                          </div>
                          <div class="flex">
                            <img
                              src="{{ checkedSex === 'other'? '/assets/icons/toggle-check-dis.svg':'/assets/icons/toggle-none-dis.svg'}}"
                              class="mx-2"
                            >
                            <p>Khác</p>
                          </div>
                        </div> -->
        
                <!--                 <div
                            class="flex justify-around mt-4 text-textblue"
                            *ngIf="!isEdit"
                          >
                            <div class="flex">
                              <img
                                src="{{checkedSex==='male'?'/assets/icons/toggle-true.svg':'/assets/icons/toggle-false.svg'}}"
                                (click)=" checkSex('male')"
                                class="mx-2"
                              >
                              <p>Nam</p>
                            </div>
                            <div class="flex">
                              <img
                                src="{{checkedSex==='female' ?'/assets/icons/toggle-true.svg':'/assets/icons/toggle-false.svg'}}"
                                (click)="checkSex('female')"
                                class="mx-2"
                              >
                              <p>Nữ</p>
                            </div>
                            <div class="flex">
                              <img
                                src="{{ checkedSex === 'other'? '/assets/icons/toggle-true.svg':'/assets/icons/toggle-false.svg'}}"
                                (click)="checkSex('other')"
                                class="mx-2"
                              >
                              <p>Khác</p>
                            </div>
                      </div> -->
              </div>
            </div>
            <div class="grid md:grid-cols-2 md:gap-6">
              <div class="relative z-0 mb-6 w-[326px] group">
                <!--    <app-primary-input
                          [isFixed]="true"
                          formControlName="idpassport"
                          [errrorMessages]="FieldErrors"
                          [formFieldControl]="fields.idpassport"
                          title="Số CMND/ CCCD/ Hộ chiếu"
                          placeholder="Số CMND/ CCCD/ Hộ chiếu"
                          [Isdisabled]="isEdit"
                          [(ngModel)]="txtIdpassport"
                        ></app-primary-input> -->
                <app-prime-input title="Số CMND/ CCCD/ Hộ chiếu" [control]="getControls('passportNumber')" [isRequire]="true" [showError]="showError"  >
        
                </app-prime-input>
              </div>
              <div class="relative z-0 mb-6 w-[326px]  group">
                <!--     <app-primary-input
                        [isFixed]="true"
                        [Primary]="false"
                        [formFieldControl]="fields.country"
                        [errrorMessages]="FieldErrors"
                        formControlName="country"
                        [(ngModel)]="txtCountry"
                        title="Quốc tịch"
                        placeholder="Quốc tịch"
                        [Isdisabled]="isEdit"
                      ></app-primary-input> -->
                <app-prime-input title="Quốc tịch" [control]="getControls('country')" >
        
                </app-prime-input>
              </div>
            </div>
        
            <div class="grid md:grid-cols-2 md:gap-6">
              <div class="relative z-0 mb-6 w-[326px]">
                <!--    <app-primary-input
                          [isFixed]="true"
                          formControlName="phonenumber"
                          [errrorMessages]="FieldErrors"
                          [formFieldControl]="fields.phonenumber"
                          [(ngModel)]="txtPhoneNumber"
                          title="Số điện thoại"
                          placeholder="Số điện thoại"
                          [Isdisabled]="isEdit"
                        ></app-primary-input> -->
                <app-prime-input title="Số điện thoại" [control]="getControls('phone')" [isRequire]="true" [showError]="showError"  >
        
                </app-prime-input>
              </div>
              <div class="relative z-0 mb-6 w-[326px]">
                <!--     <app-primary-input
                        [isFixed]="true"
                        [Primary]="false"
                        [(ngModel)]="txtAddress"
                        formControlName="address"
                        [errrorMessages]="FieldErrors"
                        [formFieldControl]="fields.address"
                        title="Địa chỉ"
                        placeholder="Địa chỉ"
                        [Isdisabled]="isEdit"
                      ></app-primary-input> -->
                <app-prime-input title="Địa chỉ" [control]="getControls('address')" >
        
                </app-prime-input>
              </div>
            </div>
        
          </div>
        
          <div class="flex justify-end ">
            <input type="button"
              class=" w-[120px]  primary-button" value="Lưu" (click)="addUser()">
          </div>
        </div>
</app-modal>


<app-modal id="adduser">
  <app-toast
    [statusToast]="statusToast"
    id="notifiAddUser"
    MessToast="{{ MessToast }}"
  ></app-toast>
  <div class="pt-[30px] w-[650px] h-auto pb-7 text-center m-auto">
    <div class="flex justify-end px-7 mb-2">
      <img
        class="h-5 w-5 cursor-pointer"
        src="/assets/icons/close.svg"
        (click)="closeModal('adduser')"
      >
    </div>
    <div class="px-16">
      <div>
        <div class="flex relative w-full">
          <input
            #searchCustomer
            (input)="searchCustomerTerm(searchCustomer.value)"
            class="hover:border-none border hover:bg-primaryblue h-10 w-full px-5 pr-16 rounded-lg text-sm font-semibold focus:outline-noneplaceholder-textblue"
            placeholder="Tìm kiếm"
          >
          <img
            src="/assets/icons/search.svg"
            class="absolute right-3 top-3"
          >
        </div>
      </div>
      <div class="mt-10 max-h-[450px] overflow-y-scroll" #customerList (scroll)="onScroll()">
        <div
          class="flex justify-between hover:bg-primarygray hover:rounded-xl pl-[20px] focus:bg-primarygray focus:rounded-xl focus:pl-[20px]"
          *ngFor="let user of ListUserWithOutComponents"
        >
          <div class="flex gap-2 mt-[10px]">
            <img
              [src]="imgUrl + user.avatar"
              class="w-10 h-10 rounded-full shadow-lg"
            >
            <div>
              <p class="text-left">
                {{ user.lastName }} {{ user.firstName }}
              </p>
              <p>{{ user.email }}</p>
            </div>
          </div>
          <input
            type="checkbox"
            class="w-[30px] h-[30px] text-white bg-color accent-textblue mt-[15px] mr-[40px] rounded-lg"
            (change)="checkCheckBoxValue($event, user.id)"
          >
        </div>
      </div>
      <div class="flex justify-end mt-[30px]">
        <input
          type="button"
          value="Thêm"
          class="noncheck rounded-[10px] px-[40px] py-[12px] border-textblue font-semibold text-blue bg-bluebuttondisable hover:bg-primaryblue hover:text-blue"
          (click)="addUserInComponent('adduser')"
        >
      </div>
    </div>
  </div>
</app-modal>
