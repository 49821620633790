import { box } from 'tweetnacl';
import { User } from './user';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiPaths } from 'src/app/core/constants/ApiPaths';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  apiurl: string = environment.api;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
  };
  createAuthHeaders() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    });
    return headers;
  }
  createHeader() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }
  param = new HttpParams();

  constructor(private httpClient: HttpClient) {}
  checklogin(username: string, password: string) {
    // username = encodeURIComponent(username);
    // password = encodeURIComponent(password);
    //const grant_type = encodeURIComponent("password");
    //const body = `username=${username}&password=${password}&grant_type=${grant_type}`;
    const body = { username, password };
    const headers = this.createHeader();
    return this.httpClient.post(
      this.apiurl + 'login',

      {
        username: username,
        password: password,
      },

      {
        headers: headers,
      }
    );
  }
  checkToken() {
    const headers = this.createAuthHeaders();

    return this.httpClient.get(this.apiurl + 'auth/check-token', {
      headers: headers,
    });
  }
  revokeToken() {
    const newHeaders = this.createAuthHeaders();
    return this.httpClient.post(this.apiurl + ApiPaths.logout,'', {
      headers: newHeaders,
    });
  }
}
//get url header
// put url body header
// post url body header
