import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
@Injectable({
  providedIn: "root",
})
export class MasterPageService {
  apUrl: string = environment.api;
  createHeader() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("accessToken") + "",
    });
    return headers;
  }

  constructor(private httpClient: HttpClient,
    private translate: TranslateService) {}
  getUser() {
    const headers = this.createHeader();
    const userId = localStorage.getItem('userId');
    return this.httpClient.get(`${this.apUrl}v1/api/users/${userId}`, {
      headers: headers,
    });
  }
  upLoadImg(id: string, urlImg: string) {
    const headers = this.createHeader();
    return this.httpClient.put(
      this.apUrl + "users/update-image/" + id,
      {
        file: urlImg,
      },
      {
        headers: headers,
      }
    );
  }

  logout(refresh_token: string) {
    const header = this.createHeader();
    return this.httpClient.post(
      this.apUrl + "oauth/token",
      { refresh_token: refresh_token },
      { headers: header }
    );
  }

  uploadAvatar(file: File): Observable<any> {
    let headers = this.createHeader();
    headers = headers.delete("Content-Type");
    const formData = new FormData();
    formData.append("file", file);
    return this.httpClient.post(
      `${this.apUrl}v1/api/users/upload-photo`,
      formData,
      {
        headers: headers,
      }
    );
  }
}
