import { ToastService } from "src/app/shared/components/toast/toast.service";
import { ModalService } from "./shared/components/modal/modal.service";
import { ProfileService } from "./features/profile-user/profile.service";
import { TranslateService } from "@ngx-translate/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { ErrorMessages as ErrorMessages } from "./shared/components/text-input/error-messages";

function noSpaceValidator(
  control: AbstractControl
): ValidationErrors | undefined {
  const value: string = control.value;
  if (value.match(/\s/)) {
    return {
      spaces: true,
    };
  }
  return undefined;
}
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "ESSO";
  txtusername = "Succeed";
  txtpassword = "";
  form: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private profilesv: ProfileService,
    private modalsv: ModalService,
    private toastsv: ToastService
  ) {
    this.form = this.formBuilder.group({
      username: [this.txtusername, [Validators.required, noSpaceValidator]],
    });
  }

  get fields() {
    return this.form.controls;
  }
  onSubmit() {
    // do nothing for now
  }
  data = "20/10/1999";
  //get data from child
  show(data: string) {
    this.txtdate = data;
  }
  txtdate = "";
  passwordFieldErrors: ErrorMessages = {
    required: () => "Mật khẩu không được để trống",
    minlength: (error) =>
      this.translate.instant("error-password-min-length", error),
  };

  usernameFieldErrors: ErrorMessages = {
    required: () => "Tên tài khoản không được để trống",
    spaces: () => this.translate.instant("error-username-with-space"),
  };
  a1 = false;
  a() {
    if (this.statusToast === "Succeed") {
      this.statusToast = "Failed";
    } else {
      this.statusToast = "Succeed";
    }
    this.OpenToast();
    console.log(this.statusToast);
  }

  getuser() {
    return this.profilesv
      .getUser("users/get/96674403-0960-4e3d-9fca-90ec5d800166")
      .subscribe((data: any) => {
        this.txtdate = data.birthDate;
      });
  }
  sdata(string: string): string {
    return string;
  }
  openModalAdd(id: string) {
    this.modalsv.open(id);
  }
  open() {
    console.log("a");
  }
  statusToast: "Succeed" | "Failed" = "Succeed";
  MessToast = "";
  OpenToast(timeout = 3000) {
    this.toastsv.open("notifi");
  }

  closeToast() {
    this.toastsv.close("notifi");
  }
  save(e: any) {
    console.log("You entered: ", this.txtusername);
  }
}
