import { OnDestroy } from '@angular/core';
import { ToastService } from 'src/app/shared/components/toast/toast.service';
import { environment } from './../../../../environments/environment';

import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessages as ErrorMessages } from 'src/app/shared/components/text-input/error-messages';
import { box } from 'tweetnacl';
import { decodeBase64, decodeUTF8, encodeBase64 } from 'tweetnacl-util';
import * as nacl from 'tweetnacl';
import * as jose from 'jose';
import { LoginService } from '../login.service';
import { User } from '../user';
import { ModalService } from 'src/app/shared/components/modal/modal.service';

function noSpaceValidator(
  control: AbstractControl
): ValidationErrors | undefined {
  const value: string = control.value;
  if (value.match(/\s/)) {
    return {
      spaces: true,
    };
  }
  return undefined;
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit ,OnDestroy{
  showError = false
  txtUserName = '';
  txtPassword = '';
  message=''
  form: FormGroup;
  countDown = 0
  returnUrl = '';
  publickey = '';
  toDay = new Date();
  statusToast = '';
  MessToast = '';
  confirmSSO: any;
  setCountDown: any
  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private loginService: LoginService,
    private r: ActivatedRoute,
    private toastSV: ToastService,
    private modalService: ModalService
  ) {
    this.form = this.formBuilder.group(
      {
        username: [this.txtUserName, [Validators.required, noSpaceValidator]],
        password: [
          this.txtPassword,
          [Validators.required, Validators.minLength(6)],
        ],
      },
      [Validators.required]
    );
    this.returnUrl = String(this.r.snapshot.queryParamMap.get('returnURL'));
    this.publickey = String(this.r.snapshot.queryParamMap.get('publickey'));
  }
  ngOnDestroy(): void {
    clearInterval(this.setCountDown)
    clearTimeout(this.confirmSSO)
  }
  get fields() {
    return this.form.controls;
  }
  onSubmit() {
    // do nothing for now
  }
  ngOnInit(): void {
    if (
      localStorage.getItem('accessToken') &&
      this.toDay <=
        new Date(
          Number(
            jose.decodeJwt(String(localStorage.getItem('refreshToken'))).exp
          ) * 1000
        )
    ) {
      this.loginService.checkToken().subscribe({
        next: () => {
          if (this.returnUrl !== 'null' || this.publickey !== 'null') {
            if (this.isAllowDomain(this.returnUrl)) {
              this.isSso(this.returnUrl, this.publickey);
            }
          } else {
            this.router.navigate(['/home']);
          }
        },
      });
    }
  }

  isAllowDomain(returnUrl: string): boolean {
    const url = new URL(returnUrl);
    const hostName = url.hostname;
    for (const domain of environment.allowDomains) {
      if (hostName.endsWith(domain)) {
        return true;
      }
    }
    return false;
  }

  isSso(reURL: string, pubKey: string) {
    const encrypted = this.encrypt(
      String(pubKey),
      String(localStorage.getItem('accessToken'))
    );
    return (window.location.href =
      reURL +
      '?&ciphertext=' +
      encodeURIComponent(encrypted.ciphertext) +
      '&ephemPubKey=' +
      encodeURIComponent(encrypted.ephemPubKey) +
      '&nonce=' +
      encodeURIComponent(encrypted.nonce));
  }

  encrypt(receiverPublicKey: string, msgParams: string) {
    const ephemeralKeyPair = box.keyPair();
    const pubKeyUInt8Array = decodeBase64(receiverPublicKey);
    const msgParamsUInt8Array = decodeUTF8(msgParams);
    const nonce = nacl.randomBytes(box.nonceLength);
    const encryptedMessage = box(
      msgParamsUInt8Array,
      nonce,
      pubKeyUInt8Array,
      ephemeralKeyPair.secretKey
    );
    return {
      ciphertext: encodeBase64(encryptedMessage),
      ephemPubKey: encodeBase64(ephemeralKeyPair.publicKey),
      nonce: encodeBase64(nonce),
    };
  }

  passwordFieldErrors: ErrorMessages = {
    required: () => 'Mật khẩu không được để trống',
    loginFailed: () => 'Mật khẩu không chính xác. Vui lòng thử lại',
    minlength: () => 'Độ dài tối thiếu của mật khẩu phải là 6 ký tự',
  };

  usernameFieldErrors: ErrorMessages = {
    required: () => 'Tên đăng nhập không được để trống',
    loginFailed: () => 'Tên đăng nhập không chính xác. Vui lòng thử lại',
    spaces: () => 'Không được có khoảng trống trong tên đăng nhập',
  };
  ischeck = false;
  imgcheck = '/assets/icons/non-checkbox.svg';

  clickcheck() {
    this.ischeck = !this.ischeck;
    if (this.ischeck) this.imgcheck = '/assets/icons/checkbox.svg';
    else this.imgcheck = '/assets/icons/non-checkbox.svg';
  }

  login() {
    if (this.txtPassword && this.txtUserName) {
      this.loginService
         .checklogin(this.txtUserName, this.txtPassword)
         .subscribe({
           next: (data: any) => {
             this.showError = false;
             localStorage.setItem('accessToken', data.access_token);
             localStorage.setItem('refreshToken', data.refresh_token);
             localStorage.setItem('user', this.txtUserName);
             localStorage.setItem('userId', data.user.id);
             localStorage.setItem('role', data.user.role);
             if (this.returnUrl === 'null' || this.publickey === 'null') {
               this.router.navigate(['/home']);
             } else {
               if (this.isAllowDomain(this.returnUrl)) {
                 this.isSso(this.returnUrl, this.publickey);
               }
             }
           },
           error: (err: any) => {
             if (err.status === 400) {
               this.showError = true;
               this.form.controls.username.setErrors({ loginFailed: true });
               this.form.controls.password.setErrors({ loginFailed: true });
             } else {
               window.alert('Kết nối sever thất bại');
             }
           },
         });

    } else {
      this.showError = true;
    }

  }

  iserr() {
    if (
      this.form.controls.username.errors ||
      this.form.controls.password.errors
    )
      return true;
    return false;
  }
  forgot() {
    this.router.navigate(['/forgot-password']);
  }

  openToast(statusToast: string, MessToast: string, timeout = 3000) {
    this.toastSV.open('notifiProfile');
    setTimeout(() => {
      this.closeToast();
    }, timeout);
  }
  closeToast() {
    this.toastSV.close('notifiProfile');
  }
  enter(e: any) {
    this.login();
  }
  confirmRedirect() {
    this.isSso(this.returnUrl, this.publickey);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  openModal() {
    this.confirmSSO
    this.modalService.open("confirmRedirect");
  }
  cancelRedirect() {
    clearInterval(this.setCountDown)
    clearTimeout(this.confirmSSO)
    this.modalService.close("confirmRedirect");
  }
}
