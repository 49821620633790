import { CommonToastService } from './../../shared/services/common-toast.service';
import { ForgotPasswordService } from './../forgot-password/forgot-password.service';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { Userdetail } from './../listing-user/userdetail';
import { TranslateService } from '@ngx-translate/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ToastService } from './../../shared/components/toast/toast.service';
import { ModalService } from './../../shared/components/modal/modal.service';
import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
} from '@angular/core';
import { UserListingService } from './user-listing.service';
import { ErrorMessages } from 'src/app/shared/components/text-input/error-messages';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Route } from '@angular/router';
import { PrimaryInputComponent } from 'src/app/shared/components/primary-input/primary-input.component';
import { environment } from 'src/environments/environment';
import { ValidatePattern } from 'src/app/shared/components/prime-input/validate-pattern';

function requiredValidator(
  control: AbstractControl
): ValidationErrors | undefined {
  const value: string = control.value;
  if (value != null) {
    if (value === '' || value.trim().length === 0) {
      return {
        required: true,
      };
    }
    return undefined;
  } else return undefined;
}

@Component({
  selector: 'app-user-listing',
  templateUrl: './user-listing.component.html',
  styleUrls: ['./user-listing.component.css'],
})
export class UserListingComponent implements OnInit, OnChanges {
  @ViewChild('formDirective') formDirective: any;
  @ViewChildren(PrimaryInputComponent)
  childInputs!: QueryList<PrimaryInputComponent>;
  searchCustomer = new Subject<string>();
  ListUser: any;
  imgUrl = `${environment.imgUrl}avatars/`;
  contentButton = this.translate.instant('add-user');
  delete: 'user_component/' | 'users/' = 'users/';
  listUserOutComponet: any[] = [];
  data: any[] = [];
  txtfirstname = '';
  txtlastname = '';
  pageSize = 9;
  txtIdpassport = '';
  txtCountry = '';
  txtPhoneNumber = '';
  txtAddress: any;
  txtEmail = '';
  txtdate = '';
  idUser = '';
  tem = '';
  statusToast!: any;
  MessToast = '';
  currentPage = 0;
  totalPage = 1;
  checkedSex: 'other' | 'male' | 'female' | '' = 'other';
  form: FormGroup;
  lastname = '';
  firstname = '';
  users$!: Observable<Userdetail[]>;
  params!: string;
  componentName = 'Danh sách nhân viên';
  isCreateUser = true;
  isCloseModal = false;
  ListUserWithOutComponents: any[] = [];
  showError = false;
  @ViewChild('customerList') scrollElement!: ElementRef<any>;
  private searchTerms = new Subject<string>();
  constructor(
    private userListingService: UserListingService,
    private modalsv: ModalService,
    private toastsv: ToastService,
    private f: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private r: ActivatedRoute,
    private forgotPasswordService: ForgotPasswordService,
    private commonToastService: CommonToastService
  ) {
    this.userForm = this.f.group(
      {
        id: [],
        email: [
          ,
          [
            Validators.required,
            Validators.pattern(ValidatePattern.email.regex),
          ],
        ],
        firstName: [, Validators.required],
        lastName: [, Validators.required],
        fullName: [],
        phone: [
          ,
          [
            Validators.required,
            Validators.pattern(ValidatePattern.phone.regex),
          ],
        ],
        birthDate: [],
        address: [],
        country: [],
        passportNumber: [, Validators.required],
        avatar: [],
        role: [],
        enabled: [],
        gender: ['OTHER'],
      },
      Validators.required
    );

    this.form = this.f.group({
      lastname: [null, requiredValidator],
      firstname: [null, requiredValidator],
      idpassport: [null, requiredValidator],
      phonenumber: [null, requiredValidator],
      country: [null],
      address: [null],
      email: [null, [requiredValidator]],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.reloadCurrentRoute();
  }

  get fields() {
    return this.form.controls;
  }
  genders = [
    { value: 'Nam', code: 'MALE' },
    { value: 'Nữ', code: 'FEMALE' },
    { value: 'Khác', code: 'OTHER' },
  ];

  checkValueGender(code: string) {
    if (code === this.getControls('gender').value) {
      return `/assets/icons/toggle-true.svg`;
    } else {
      return `/assets/icons/toggle-false.svg`;
    }
  }
  changeGender(code: string) {
    this.getControls('gender').setValue(code);
  }

  componentsId!: string;

  public get isCheckPage(): string {
    return String(localStorage.getItem('num'));
  }

  ngOnInit() {
    this.search();
    this.checkPage();
    this.searchAllCustomer();
  }
  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  checkPage() {
    const url = this.r.snapshot.params['id'];
    if (url === 'user') {
      this.delete = 'users/';
    } else {
      this.isCreateUser = false;
      this.componentsId = this.r.snapshot.params['id'];
      this.delete = 'user_component/';
    }
    this.loadApi();
  }
  loadApi() {
    if (this.tem.length > 0) {
      return this.userListingService;
    } else
      return this.userListingService
        .getUser(String(this.getUrl()))
        .subscribe((data: any) => {
          console.log(data);
          this.data = data.data;
          this.totalPage = data.totalPages;
          this.componentName =
            data.data[0].component != null
              ? data.data[0].componentName
              : 'Danh sách nhân viên';
        });
  }
  getUrl() {
    if (this.r.snapshot.params['id'] === 'user') {
      return `v1/api/users/search?page=${this.currentPage}&limit=${this.pageSize}&orderBy=firstName&sortBy=asc`;
    }
    return `v1/api/components/{id}/users?componentId=${this.componentsId}&page=${this.currentPage}&limit=${this.pageSize}&sortBy=asc`;
  }

  checkSex(sex: 'other' | 'male' | 'female') {
    this.checkedSex = sex;
  }
  changesize(e: any) {
    this.pageSize = e.target.value;
    this.currentPage = 0;
    this.loadApi();
  }

  openModal(id: string, lastname: string, firstname: string, iduser: string) {
    this.modalsv.open(id);
    this.lastname = lastname;
    this.firstname = firstname;
    this.idUser = iduser;
  }

  openModalAdd() {
    if (this.isCreateUser) this.modalsv.open('createUser');
    else {
      this.modalsv.open('adduser');
      this.ListUserWithOutComponents = [];
      this.getUserWithOutComponents();
    }
  }
  openToast(timeout = 3000) {
    this.toastsv.open('notifiListUser');
    setTimeout(() => {
      this.closeToast();
    }, timeout);
  }
  closeToast() {
    this.toastsv.close('notifiListUser');
  }

  closeModal(id: string) {
    this.checkSex('other');
    this.txtdate = 'dd/mm/yyyy';
    this.modalsv.close(id);
    this.userForm.reset();
  }

  deleteUser() {
    if (this.componentsId) {
      this.deleteUserInComponent();
    } else {
      this.deleteUsers();
    }
  }

  deleteUsers() {
    this.userListingService.deleteUser(this.idUser).subscribe({
      next: () => {
        this.commonToastService.show('Xóa người dùng thành công', 'success');
        this.statusToast = 'Succeed';
        this.MessToast = 'Xóa người dùng thành công';
        this.openToast();
        this.loadApi();
        this.closeModal('delete');
      },
      error: (err) => {
        console.log(err);
        if (err.status === 200) {
          this.statusToast = 'Succeed';
          this.MessToast = 'Xóa người dùng thành công';
          this.openToast();
          this.loadApi();
          this.closeModal('delete');
        } else {
          this.statusToast = 'Failed';
          this.MessToast = 'Xóa người dùng thất bại';
          this.openToast();
          this.loadApi();
          this.closeModal('delete');
        }
      },
    });
  }

  deleteUserInComponent() {
    this.userListingService
      .deleteUserInComponent(this.componentsId, this.idUser)
      .subscribe({
        next: () => {
          this.commonToastService.show('Xóa người dùng thành công', 'success');
          this.statusToast = 'Succeed';
          this.MessToast = 'Xóa người dùng thành công';
          this.openToast();
          this.loadApi();
          this.closeModal('delete');
        },
        error: (err) => {
          console.log(err);
          if (err.status === 200) {
            this.statusToast = 'Succeed';
            this.MessToast = 'Xóa người dùng thành công';
            this.openToast();
            this.loadApi();
            this.closeModal('delete');
          } else {
            this.statusToast = 'Failed';
            this.MessToast = 'Xóa người dùng thất bại';
            this.openToast();
            this.loadApi();
            this.closeModal('delete');
          }
        },
      });
  }
  search() {
    this.searchTerms
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term: string) => this.userListingService.searchUser(term))
      )
      .subscribe((data: any) => {
        this.data = data.data;
        this.totalPage = data.totalPages;
      });
  }

  searchTerm(term: string): void {
    this.currentPage = 0;
    if (!this.componentsId) {
      this.searchTerms.next(
        `v1/api/users/search?keyword=${term}&page=${this.currentPage}&limit=${this.pageSize}&orderBy=firstName&sortBy=asc`
      );
    } else {
      this.searchTerms.next(
        `v1/api/components/{id}/users?search=${term}&componentId=${this.componentsId}&page=${this.currentPage}&limit=${this.pageSize}&sortBy=asc`
      );
    }
  }
  pageSearch() {
    if (this.componentsId) {
      this.userListingService
        .searchUser(
          'user_component/search/pagination/' +
            this.componentsId +
            '?pageNo=' +
            this.currentPage +
            '&pageSize=' +
            this.pageSize +
            '&search=' +
            this.tem
        )
        .subscribe((data: any) => {
          this.data = data.content;
          this.totalPage = data.totalPages;
          console.log('page search');
        });
    } else {
      this.userListingService
        .searchUser(
          'users/pagination/search?pageNo=' +
            this.currentPage +
            '&pageSize=' +
            this.pageSize +
            '&search=' +
            this.tem
        )
        .subscribe((data: any) => {
          this.data = data.content;
          this.totalPage = data.totalPages;
          console.log('page search');
        });
    }
  }

  FieldErrors: ErrorMessages = {
    required: () => 'Không được để trống',
    noemail: () => 'Không đúng định dạng Email',
  };

  selectUser(id: string) {
    this.router.navigate(['profile/' + id]);
  }
  show(value: any) {
    this.txtdate = value;
    console.log(value);
  }
  addUser() {
    if (this.userForm.invalid) {
      this.showError = true;
    } else {
      this.userListingService.addUser(this.userForm.value).subscribe({
        next: (value) => {
          this.loadApi();
          this.statusToast = 'Succeed';
          this.MessToast = 'Thêm người dùng vào bộ phận thành công';
          this.openToast();
          this.closeModal('createUser');
        },
        error: (err) => {
          this.statusToast = 'Failed';
          if (err.error.errorCode === 10200) {
            this.MessToast = 'Email đã được sử dụng ';
            this.openToast();
          } else {
            this.MessToast = 'Thêm người dùng vào bộ phận thất bại';
            this.openToast();
            this.loadApi();
            this.closeModal('createUser');
          }
        },
        complete: () => {
          this.userForm.reset();
        },
      });
    }
  }

  log(Email: string) {
    this.forgotPasswordService.checkemail(Email).subscribe({
      next: () => {
        this.statusToast = 'Succeed';
        this.MessToast = 'Thêm người dùng vào bộ phận thành công';
        this.openToast();
      },
      error: (err) => {
        this.statusToast = 'Failed';
        this.MessToast = 'Thêm người dùng vào bộ phận thất bại';
        this.openToast();
      },
    });
  }
  ListPage: any;
  getListPage(checkSearch: boolean) {
    if (checkSearch) {
      this.ListPage = this.getListButtonOfPaggings(
        this.totalPage,
        this.currentPage
      );
    } else this.ListPage = [1];

    return this.ListPage;
  }
  getListButtonOfPaggings(totalPage: number, currentPage: number): string[] {
    // i = current page, n = totalPage
    const result: string[] = [
      '1',
      '...',
      'i-1',
      (currentPage + 1).toString(),
      'i+1',
      '...',
      totalPage.toString(),
    ];
    if (totalPage === 0) {
      result[0] = '1';
      result[1] = '';
      result[2] = '';
      result[5] = '';
      result[4] = '';
      result[6] = '';
    }
    if (currentPage === 0) {
      result[0] = '';
      result[1] = '';
      result[2] = '';
    } else if (currentPage === 1) {
      result[0] = '';
      result[1] = '';
      result[2] = currentPage.toString();
    } else if (currentPage === 2) {
      result[1] = '';
      result[2] = currentPage.toString();
    } else {
      result[2] = currentPage.toString();
    }

    if (currentPage === totalPage - 1) {
      result[5] = '';
      result[4] = '';
      result[6] = '';
    } else if (currentPage === totalPage - 2) {
      result[6] = '';
      result[5] = '';
      result[4] = (currentPage + 2).toString();
    } else if (currentPage === totalPage - 3) {
      result[5] = '';
      result[4] = (currentPage + 2).toString();
    } else {
      result[4] = (currentPage + 2).toString();
    }
    return result.filter((s) => s.length);
  }
  onPagingButtonClicked(s: any) {
    if (!(s === '...')) {
      this.currentPage = this.isNumber(s) - 1;
      if (this.tem.length === 0) {
        this.loadApi();
      } else {
        this.pageSearch();
      }
    }
  }

  isNumber(s: string) {
    return Number(s);
  }
  check() {
    if (this.currentPage === 0 || this.currentPage === this.totalPage)
      return false;
    else return true;
  }
  listUser: string[] = [];
  checkCheckBoxValue(e: any, id: string) {
    if (e.target.checked) {
      this.listUser.push(id);
    } else
      this.listUser.splice(
        this.listUser.findIndex((i) => {
          return i === id;
        })
      );
  }

  getUserWithOutComponents() {
    this.userListingService
      .getUser(
        `v1/api/users/search?keyword=${this.valueSearchTerm}&page=${this.userPage}&limit=${this.pageSize}&orderBy=firstName&sortBy=asc`
      )
      .subscribe((data: any) => {
        this.totalUserPage = data.totalPages;
        this.ListUserWithOutComponents = this.ListUserWithOutComponents.concat(
          data.data
        );
      });
  }

  addUserInComponent(idModal: string) {
    this.userListingService
      .addUseInComponent(this.componentsId, this.listUser)
      .subscribe({
        next: (value) => {
          this.closeModal(idModal);
          this.statusToast = 'Succeed';
          this.MessToast = 'Thêm người dùng thành công';
          this.openToast();
        },
        error: (err) => {
          this.closeModal(idModal);
          this.statusToast = 'Failed';
          this.MessToast = 'Thêm người dùng thất bại';
          this.openToast();
        },
        complete: () => {
          this.loadApi();
        },
      });

    this.ListUser = [];
  }
  userPage = 0;
  totalUserPage = 1;
  onScroll() {
    if (
      this.scrollElement.nativeElement.scrollHeight ==
      this.scrollElement.nativeElement.clientHeight +
        this.scrollElement.nativeElement.scrollTop
    ) {
      if (this.userPage < this.totalUserPage) {
        this.userPage = this.userPage + 1;
        this.getUserWithOutComponents();
      }
    }
  }
  valueSearchTerm = '';
  searchCustomerTerm(value: string) {
    this.valueSearchTerm = value;
    this.searchCustomer.next(
      `v1/api/users/search?keyword=${value}&page=${this.currentPage}&limit=${this.pageSize}&orderBy=firstName&sortBy=asc`
    );
  }
  searchAllCustomer() {
    this.searchCustomer
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term: string) => this.userListingService.searchUser(term))
      )
      .subscribe((data: any) => {
        this.totalUserPage = data.totalPages;
        this.ListUserWithOutComponents = [];
        this.ListUserWithOutComponents = this.ListUserWithOutComponents.concat(
          data.data
        );
      });
  }
  userForm: FormGroup;
  getControls(nameControl: string) {
    return this.userForm.controls[nameControl] as FormControl;
  }
}

function noEmail(control: AbstractControl): ValidationErrors | undefined {
  if (control.value != null) {
    if (!control.value?.includes('@outlook.com.vn')) {
      return {
        noemail: true,
      };
    }
    return undefined;
  } else return undefined;
}
