<div class="relative">
  <label class="text-[14px] leading-5 font-semibold" [ngClass]="disabled?'text-textgray':'text-black'">{{title}} <span
      *ngIf="isRequire" class="text-red">*</span></label>
  <!--  <input  [readOnly]="disabled" type="text"  [formControl]="control" [placeholder]="title" /> -->
  <input [readOnly]="disabled" type="text"
  [ngClass]="disabled?'text-textgray':'text-black'"
   [formControl]="control" [placeholder]="title" class="block w-full h-[50px] py-[10px] px-[12px] rounded-lg border border-linegray  text-textblue text-[16px] leading-6 tracking-wide font-semibold
       focus:bg-white focus:text-black focus:outline-none disabled:text-scgray ">
  <label *ngIf="control.invalid && showError" class="text-red absolute">
    {{patternError()|translate}}
  </label>


</div>