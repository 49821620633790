import { LoginService } from './../../login/login.service';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { query } from '@angular/animations';
import { CommonToastService } from 'src/app/shared/services/common-toast.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
})
export class LogoutComponent {
  constructor(
    private loginService: LoginService,
    private r: ActivatedRoute,
    private router: Router,
    private commonToastService: CommonToastService
  ) {
    this.loginService.revokeToken().subscribe({
      next: () => {
        const returnURL = String(
          this.r.snapshot.queryParamMap.get('returnURL')
        );
        this.backToComponent(returnURL)
      /*   const returnURL = String(
          this.r.snapshot.queryParamMap.get('returnURL')
        );
        const publickey = String(
          this.r.snapshot.queryParamMap.get('publickey')
        );
        const queryParam = {
          returnURL,
          publickey,
        };
        localStorage.removeItem('accessToken');
        localStorage.removeItem('num');
        localStorage.removeItem('user');
        setTimeout(() => {
          this.router.navigate(['/login'], {
            queryParams: queryParam,
          });
        }, 300); */
      },
      error: () => {
        const returnURL = String(
          this.r.snapshot.queryParamMap.get('returnURL')
        );
     this.backToComponent(returnURL)
        this.commonToastService.showError('InternalServerError');
      },
    });
  }
  backToComponent(returnURL: string) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('num');
    localStorage.removeItem('user');
    if (returnURL) {
      window.location.href = returnURL
    } else {
      this.router.navigate(['/login'])
    }
  }
}
