export const environment = {
  production: false,
  allowDomains: [
    'swerp.com',
    'crm.swerp.com',
    'cart.swerp.com',
    'qa-sso.swerp.com',
    'localhost',
  ],
  api: 'https://api-swwebdeverp-develop.tma-swerp.com/sso-service/',
  crmUrl: 'https://crm-dev.tma-swerp.com/',
  dashBoardUrl: 'https://dev-statistics.tma-swerp.com/dashboard',
  cartUrl: 'https://dev-cart.tma-swerp.com/dashboard',
  imgUrl: `https://api-swwebdeverp-develop.tma-swerp.com/sso-service/static/images/`,
};
