import { LoginService } from './../../features/login/login.service';
import { RefreshTokenResponse } from '../models/refresh-token-response';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import * as jose from 'jose';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { onErrorResumeNext } from 'rxjs/operators';
import { ApiPaths } from 'src/app/core/constants/ApiPaths';

@Injectable()
export class UserInterceptor implements HttpInterceptor {
  constructor(
    public router: Router,
    private http: HttpClient,
    private loginService: LoginService
  ) {}
  url = environment.api;

  createHeader() {
    const headers = new HttpHeaders({
      Authorization: 'Basic c3dlcnA6c2VjcmV0',
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    return headers;
  }
  intercept(request: HttpRequest<void>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      map((event) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.Unauthorized) {
          this.deleteToken();
          const logoutApiUrl = environment.api + ApiPaths.logout
          if (request.url != logoutApiUrl) {
            this.router.navigate(["login"])
          } 
        }
        return throwError(() => error);
      })
    );
  }

  private refreshToken(): Observable<RefreshTokenResponse> {
    let refreshToken = localStorage.getItem('refreshToken');
    refreshToken = encodeURIComponent(String(refreshToken));
    const grant_type = encodeURIComponent('refresh_token');
    const headers = this.createHeader();
    const body = `grant_type=${grant_type}&refresh_token=${refreshToken}`;
    return this.http.post<RefreshTokenResponse>(
      this.url + 'oauth/token',

      body,
      {
        headers: headers,
      }
    );
  }
  private updateNewToken(newTokenResponse: RefreshTokenResponse) {
    // update vào local storage
    localStorage.setItem('refreshToken', newTokenResponse.refresh_token);
    localStorage.setItem('accessToken', newTokenResponse.access_token);
  }
  private deleteToken() {
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
  }
}
