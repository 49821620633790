<div [formGroup]="form"
  class="bg-[url('/assets/img/login.avif')] px-2 md:mx-0 pt-[20%] lg:pt-[15%]  font-Mulish object-fill w-full h-fit min-h-[100vh] pb-[5%] bg-no-repeat bg-cover bg-center">
  <div class="md:w-[27.5rem] h-auto m-auto rounded-lg bg-white  p-10">
    <img src="/assets/img/logo_tma.png" class="m-auto w-[7.75rem] h-[7.75rem]">
    <p class="text-center font-bold text-2xl mt-6">Đăng nhập</p>
    <div>
      <div class=" mt-10 ">
        <app-primary-input formControlName="username" [showError]="showError" [formFieldControl]="fields.username"
          [(ngModel)]="txtUserName" [errrorMessages]="usernameFieldErrors" title="Tên đăng nhập" [Primary]="false"
          placeholder="Tên đăng nhập">
        </app-primary-input>
      </div>
      <div class="mt-5 ">
        <app-primary-input [showError]="showError" formControlName="password" [formFieldControl]="fields.password"
          [(ngModel)]="txtPassword" [errrorMessages]="passwordFieldErrors" title="Mật khẩu" [isPassword]="true"
          [Primary]="false" placeholder="Mật khẩu" (getEnter)="enter($event)">
        </app-primary-input>
      </div>
    </div>
    <p (click)="forgot()" class=" mt-4  cursor-pointer text-end text-[#3385FF] font-semibold text-sm ">
      Quên mật khẩu?
    </p>
    <button (click)="login()"
      class="bg-[#3385FF] font-semibold text-sm lg:text-base mt-6 py-[16px] w-full leading-6 text-white rounded-lg uppercase shadow-lg">
      Đăng nhập
    </button>
  </div>
  <app-toast [statusToast]="statusToast" id="notifiLogin" MessToast="{{MessToast}}"></app-toast>
  <app-modal id="confirmRedirect" class="px-10">
    <div class="p-4 pt-[30px] h-auto pb-7 text-base font-medium m-auto">
      <p>Bạn sẽ được chuyển hướng sau <span class="text-red">{{countDown}}</span> giây. Nhấn chuyển hướng nếu bạn không muốn chờ</p>
      <div class="mt-10 flex justify-end">
        <button (click)="cancelRedirect()" class="rounded-xl px-[40px] py-[12px] bg-buttongray text-black mr-10">Hủy</button>
        <button (click)="confirmRedirect()" class="rounded-xl px-[40px] py-[12px] bg-blue text-white ">Chuyển
          hướng</button>
      </div>

    </div>
  </app-modal>
</div>
<!--   <div
  class="lg:flex font-Mulish w-full h-full lg:bg-white "
  [formGroup]="form"
>
  <app-toast
    [statusToast]="statusToast"
    id="notifiLogin"
    MessToast="{{MessToast}}"
  ></app-toast>
  <div class="lg:w-[40%] w-full lg:bg-white  h-auto bg-textblue ">
    <div class="bg-textblue text-center pt-10 px-28 text-white w-full h-[12rem] lg:hidden">
      <img
        src="/assets/SSO.png"
        class="m-auto"
      >
      <p class="border-t-2 border-white pt-3 mt-4 text-base leading-6 font-semibold">Enterprise Resource Planning system</p>
    </div>
    <div class=" bg-white  lg:max-w-[30rem] h-auto
     lg:h-[448px] pt-4 lg:pt-[10rem] xl:pt-[20rem] mb-[175px] m-auto px-5 rounded-t-3xl">
      <div>
        <p class="text-2xl leading-6">Đăng nhập</p>
        <p class="text-[30px] font-bold leading-9 mt-3">Chào mừng quay lại!</p>
      </div>
      <div>
        <div class=" mt-10 ">
          <app-primary-input
            formControlName="username"
            [formFieldControl]="fields.username"
            [(ngModel)]="txtUserName"
            [errrorMessages]="usernameFieldErrors"
            title = "Tên đăng nhập"
            [Primary]="false"
            placeholder="Tên đăng nhập"
          >
          </app-primary-input>
        </div>
        <div class="mt-5 ">
          <app-primary-input
            formControlName="password"
            [formFieldControl]="fields.password"
            [(ngModel)]="txtPassword"
            [errrorMessages]="passwordFieldErrors"
            title="Mật khẩu"
            [isPassword]="true"
            [Primary]="false"
            placeholder="Mật khẩu"
            (getEnter)="enter($event)"
          >
          </app-primary-input>
        </div>
      </div>
      <div class="text-right text-[14px] mt-5 text-textblue">
        <span
          (click)="forgot()"
          class="cursor-pointer "
        >
        Quên mật khẩu?
        </span>
      </div>
      <div class="text-center mt-10 pb-5">
        <button
          routerLink="/home"
          (click)="login()"
          class="bg-primaryYellowe text-[20px] py-[16px] w-full leading-6 text-white rounded-lg uppercase shadow-lg "
        >
        Đăng nhập
        </button>
      </div>
    </div>
  </div>
  <div class="lg:block hidden justify-center w-[60%] h-full relative text-center bottom-0 flex-shrink">
    <img
      src="/assets/img/login.png"
      class="lg:min-h-[805px] h-full w-full bg-cover aspect-[4/3]"
    >
    <div class="absolute top-[50%] left[50%] -translate-y-1/2 w-full">
      <div class="text-white text-center font-bold leading-tight text-9xl h-fit w-full">SSO</div>
      <div class="border border-white border-solid mx-28"></div>
      <div class="mt-11 text-white w-full leading-9 text-[2.125rem]">Enterprise Resource Planning System</div>
    </div>
  </div>
</div>  -->
