<div class="min-h-screen w-full relative flex ">
  <app-toast
    [statusToast]="statusToast"
    id="notifiMasterPage"
    MessToast="{{MessToast}}"
  ></app-toast>
  <div class="flex">   
    <div
      class="w-[70px] flex justify-center  py-[56px] h-full"
      id="sidebar"
      *ngIf="check"
    >
      <div>
        <img
          class="w-10 h-10 ml-2 mb-4 cursor-pointer"
          src="/assets/icons/sidebar.svg"
          (click)="changeBar()"
        >

        <div>
          <div>
            <div class="flex">
              <img
                class="ml-2 rounded-full w-10 h-10"
                src="{{_avatar}}"
              >
              <div
                *ngIf="!menu"
                class="ml-2"
              >
                <p class="ml-3 text-scgray">{{role}}</p>
                <p class="text-003760 text-base font-semibold">{{username}}</p>
              </div>
            </div>
            <p class="border-b border-linegray mt-3 "></p>
          </div>


          <div
            class=" {{isSelected==='1'?
          'fill-textblue':
          'fill-blueBADCEE  hover:fill-textblue '}}
           rounded-l-xl pl-2 py-2 mt-5 flex"
            (click)="selected('1')"
            routerLink="/home"
          >


            <svg
              class="w-10 h-10"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M20.2567 11.6873L11.694 3.19345C11.6323 3.13212 11.5591 3.08347 11.4785 3.05028C11.3979 3.01709 11.3114 3 11.2242 3C11.1369 3 11.0505 3.01709 10.9699 3.05028C10.8892 3.08347 10.816 3.13212 10.7544 3.19345L2.1916 11.6873C1.94214 11.9349 1.80078 12.2713 1.80078 12.6221C1.80078 13.3506 2.39741 13.9428 3.13124 13.9428H4.03346V20.0036C4.03346 20.3689 4.33073 20.664 4.69869 20.664H9.89371V16.0415H12.222V20.664H17.7497C18.1176 20.664 18.4149 20.3689 18.4149 20.0036V13.9428H19.3171C19.6705 13.9428 20.0094 13.8045 20.2588 13.5548C20.7764 13.0389 20.7764 12.2032 20.2567 11.6873Z"></path>
            </svg>
          </div>
          <div
            routerLink="/UserList/user"
            class=" 
          {{isSelected==='2'?
            'fill-textblue':
            'fill-blueBADCEE  hover:fill-textblue '}}
          
          or-pointer rounded-l-xl pl-2 py-2 mt-5 flex"
            (click)="selected('2')"
            *ngIf="checkRole()"
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1336_7249)">
                <path d="M19.5 20C22.815 20 25.5 17.315 25.5 14C25.5 10.685 22.815 8 19.5 8C16.185 8 13.5 10.685 13.5 14C13.5 17.315 16.185 20 19.5 20ZM19.5 23C15.495 23 7.5 25.01 7.5 29V32H31.5V29C31.5 25.01 23.505 23 19.5 23Z"></path>
              </g>
              <defs>
                <clipPath id="clip0_1336_7249">
                  <rect
                    width="40"
                    height="40"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div
           
            routerLink="/profile/user"
            (click)="selected('3')"
            class="  {{isSelected==='3'?
          'stroke-textblue':
          'stroke-blueBADCEE  hover:stroke-textblue '}}  rounded-l-xl pl-2 py-2 mt-5 flex"
          >
            <svg
              class="w-10 h-10"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.474 5.40783L18.592 7.52483M17.836 3.54283L12.109 9.26983C11.8131 9.56532 11.6113 9.94181 11.529 10.3518L11 12.9998L13.648 12.4698C14.058 12.3878 14.434 12.1868 14.73 11.8908L20.457 6.16383C20.6291 5.99173 20.7656 5.78742 20.8588 5.56256C20.9519 5.33771 20.9998 5.09671 20.9998 4.85333C20.9998 4.60994 20.9519 4.36895 20.8588 4.14409C20.7656 3.91923 20.6291 3.71492 20.457 3.54283C20.2849 3.37073 20.0806 3.23421 19.8557 3.14108C19.6309 3.04794 19.3899 3 19.1465 3C18.9031 3 18.6621 3.04794 18.4373 3.14108C18.2124 3.23421 18.0081 3.37073 17.836 3.54283V3.54283Z"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M19 15V18C19 18.5304 18.7893 19.0391 18.4142 19.4142C18.0391 19.7893 17.5304 20 17 20H6C5.46957 20 4.96086 19.7893 4.58579 19.4142C4.21071 19.0391 4 18.5304 4 18V7C4 6.46957 4.21071 5.96086 4.58579 5.58579C4.96086 5.21071 5.46957 5 6 5H9"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <div>
            <div
              (click)="openModal('logout')"
              class="     cursor-pointer stroke-textblue  pl-2 rounded-full flex    absolute bottom-40"
            >
              <svg
                class="mt-2 h-10 w-10"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.3125 8.0625L20.25 12L16.3125 15.9375"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M9.75 12H20.25"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M9.75 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V4.5C3.75 4.30109 3.82902 4.11032 3.96967 3.96967C4.11032 3.82902 4.30109 3.75 4.5 3.75H9.75"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
 <!-- end -->
    <div>
      <div
        class="w-[320px] pl-6  py-[56px] h-full font-semibold" 
        id="sidebar"
        *ngIf="!check"
      >
        <div>
          <img
            class="w-10 h-10 ml-2 mb-4 cursor-pointer"
            src="/assets/icons/BackArrow.svg"
            (click)="changeBar()"
          >
          <div>
            <div>
              <!-- avt -->
              <div class="flex">
                <div class="h-20 relative items-end flex w-20 rounded-full overflow-hidden">
                  <img
                    class="w-full h-full"
                    src="{{_avatar}}"
                  >
                  <label
                    *ngIf="checkImg"
                    class="absolute h-8 w-full hover:opacity-90 opacity-0 bg-white pt-2"
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-edit w-4 h-4 m-auto "
                      >
                        <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
                        <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
                      </svg>
                      <input
                        type='file'
                        class="hidden"
                        accept="image/*"
                        (change)="onSelect($event)"
                      >
                    </div>
                  </label>
                  <label
                    *ngIf="!checkImg"
                    class="absolute h-8 w-full opacity-90  bg-white pt-2"
                  >
                    <div class="flex justify-around">
                      <img
                        class="ml-2 w-2 h-2"
                        src="/assets/icons/close.svg"
                        (click)="cancelChange()"
                      >
                      <img
                        class="mt-[-8px]"
                        src="/assets/icons/Down.svg"
                        (click)="changeImg()"
                      >
                    </div>
                  </label>
                </div>
                <div class="ml-2">
                  <p class="ml-3 text-scgray">{{role}}</p>
                  <p class="text-003760 text-base font-semibold">{{username}}</p>
                </div>
              </div>
              <p class="border-b border-linegray mt-3 "></p>
            </div>
            <!-- menu -->
            <div>
              <div
                (click)="selected('1')"
                routerLink="/home"
                class="{{isSelected ==='1'?'text-white fill-white bg-textblue':'fill-textblue text-textblue hover:text-white hover:bg-textblue  hover:fill-white '}} rounded-l-xl pl-2 py-2 mt-5 flex"
              >
                <svg
                  class="w-10 h-10"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M20.2567 11.6873L11.694 3.19345C11.6323 3.13212 11.5591 3.08347 11.4785 3.05028C11.3979 3.01709 11.3114 3 11.2242 3C11.1369 3 11.0505 3.01709 10.9699 3.05028C10.8892 3.08347 10.816 3.13212 10.7544 3.19345L2.1916 11.6873C1.94214 11.9349 1.80078 12.2713 1.80078 12.6221C1.80078 13.3506 2.39741 13.9428 3.13124 13.9428H4.03346V20.0036C4.03346 20.3689 4.33073 20.664 4.69869 20.664H9.89371V16.0415H12.222V20.664H17.7497C18.1176 20.664 18.4149 20.3689 18.4149 20.0036V13.9428H19.3171C19.6705 13.9428 20.0094 13.8045 20.2588 13.5548C20.7764 13.0389 20.7764 12.2032 20.2567 11.6873Z"></path>
                </svg>

                <span class="mt-3 ml-3">Trang chủ</span>
              </div>
              <!-- 1 -->
              <div
                (click)="selected('2')"
                *ngIf="checkRole()"
                routerLink="/UserList/user"
                class=" {{isSelected ==='2'?'text-white fill-white bg-textblue':'text-textblue hover:text-white hover:bg-textblue  hover:fill-white '}}   cursor-pointer fill-textblue  rounded-l-xl pl-2 py-2 mt-5 flex"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1336_7249)">
                    <path d="M19.5 20C22.815 20 25.5 17.315 25.5 14C25.5 10.685 22.815 8 19.5 8C16.185 8 13.5 10.685 13.5 14C13.5 17.315 16.185 20 19.5 20ZM19.5 23C15.495 23 7.5 25.01 7.5 29V32H31.5V29C31.5 25.01 23.505 23 19.5 23Z"></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_1336_7249">
                      <rect
                        width="40"
                        height="40"
                      ></rect>
                    </clipPath>
                  </defs>
                </svg>
                <span class="mt-3 ml-3 hover:text-white">Quản lý nhân viên</span>
              </div>
              <!-- 2 -->
              <div class="  hover:text-white  text-textblue  stroke-textblue hover:bg-blue hover:stroke-white  rounded-l-xl pl-2 py-2 mt-5 flex cursor-pointer">
                <svg
                  class="w-10 h-10"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.474 5.40783L18.592 7.52483M17.836 3.54283L12.109 9.26983C11.8131 9.56532 11.6113 9.94181 11.529 10.3518L11 12.9998L13.648 12.4698C14.058 12.3878 14.434 12.1868 14.73 11.8908L20.457 6.16383C20.6291 5.99173 20.7656 5.78742 20.8588 5.56256C20.9519 5.33771 20.9998 5.09671 20.9998 4.85333C20.9998 4.60994 20.9519 4.36895 20.8588 4.14409C20.7656 3.91923 20.6291 3.71492 20.457 3.54283C20.2849 3.37073 20.0806 3.23421 19.8557 3.14108C19.6309 3.04794 19.3899 3 19.1465 3C18.9031 3 18.6621 3.04794 18.4373 3.14108C18.2124 3.23421 18.0081 3.37073 17.836 3.54283V3.54283Z"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M19 15V18C19 18.5304 18.7893 19.0391 18.4142 19.4142C18.0391 19.7893 17.5304 20 17 20H6C5.46957 20 4.96086 19.7893 4.58579 19.4142C4.21071 19.0391 4 18.5304 4 18V7C4 6.46957 4.21071 5.96086 4.58579 5.58579C4.96086 5.21071 5.46957 5 6 5H9"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
                <div
                  class="flex"
                  (click)="openEdit()"
                >
                  <span class="mt-3 ml-3 hover:text-white">Chỉnh sửa tài khoản</span>
                  <div class="mt-1">
                    <svg
                      class="h-10 w-10"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <!-- 3 -->
              <div
                class=" w-auto "
                *ngIf="edit"
              >
                <div class="cursor-pointer">
                  <div
                    (click)="selected('4')"
                    routerLink="/profile/user"
                    class="{{isSelected==='4' ?' fill-blue text-blue bg-blueBADCEE':'text-4EA0D1 fill-4EA0D1'}} hover:fill-blue hover:text-blue hover:bg-blueBADCEE rounded-l-xl pl-10 py-2 mt-5 flex"
                  >
                    <svg class="my-auto" width="24" height="24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.5 21S3 21 3 19.5s1.5-6 9-6 9 4.5 9 6-1.5 1.5-1.5 1.5h-15zm7.5-9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9z"/>
                    </svg>
                  
                    <span class="my-auto ml-3">Thông tin cá nhân</span>
                  </div>

                  <div>
                    <div
                      (click)="selected('5')"
                      routerLink="/changepassword"
                      class="{{isSelected==='5'?' fill-blue text-blue bg-blueBADCEE':'text-4EA0D1 fill-4EA0D1'}} hover:fill-blue hover:text-blue hover:bg-blueBADCEE  rounded-l-xl pl-10 py-2 mt-5 flex"
                    >
                      <svg class="my-auto" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 10h-8.35A5.99 5.99 0 0 0 7 6c-3.31 0-6 2.69-6 6s2.69 6 6 6a5.99 5.99 0 0 0 5.65-4H13l2 2 2-2 2 2 4-4.04L21 10zM7 15c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"/>
                      </svg>
                    
                      <span class="my-auto ml-3">Đổi mật khẩu</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 4 -->

      </div>
    </div>
  </div>

 <div class="relative flex justify-center"
  *ngIf="!check" 
  (click)="openModal('logout')">
   <div
  class="w-[200px] absolute bottom-[56px]  stroke-textblue focus:fill-textblue hover:stroke-white hover:fill-white hover:text-white text-textblue cursor-pointer fill-textblue hover:bg-textblue border-textblue border  rounded-full flex"
    >
      <svg class="pl-4" width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.313 8.063 20.25 12l-3.938 3.938M9.75 12h10.5M9.75 20.25H4.5a.75.75 0 0 1-.75-.75v-15a.75.75 0 0 1 .75-.75h5.25" stroke="#00477D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <span class=" ml-5 my-auto hover:text-white font-semibold leading-6"> Đăng xuất </span>
  </div>
  </div> 
 </div>

</div>


<div class="w-full pl-[60px] bg-whitef">
  <router-outlet></router-outlet>
</div>

</div>
<app-modal id="logout">
  <div class=" pt-[30px] w-[450px] pb-7 text-center m-auto  font-Mulish">
      <div class="flex justify-between px-7 mb-2">
        <span class="text-redtextremove font-Mulish text-2xl"> Đăng xuất</span>
        <img
          class="h-5 w-5 cursor-pointer"
          src="/assets/icons/close.svg"
          (click)="closeModal('logout')"
        >
      </div>
      <p class="border border-linegray"></p>
      <div class="px-7 mb-2 flex-col text-left font-semibold">
        <p  class="mt-[20px]">Bạn có chắc chắn muốn đăng xuất tài khoản? </p>
      </div>
      
      <div class="pt-6 px-7 mb-2 flex justify-between font-semibold ">
        <input
          class="bg-whitef text-buttongray py-[4px] px-[24px] w-[140px] rounded-lg border border-buttongray cursor-pointer "
          type="button"
          value="Hủy"
          (click)="closeModal('logout')">
        <input
        class="bg-redbuttonremove text-redtextremove py-[4px] px-[24px] w-[140px] rounded-lg border border-buttongray cursor-pointer"
        type="button"
        value="Đồng ý"
        (click)="logOut('logout')">
      </div>
    </div>
  </app-modal>
