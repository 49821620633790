import { ListingModule } from "./../features/listing/listing.module";
import { MasterPageLoginComponent } from "./components/master-page-login/master-page-login.component";
import { RouterModule } from "@angular/router";
import { TranslateService, TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TextInputComponent } from "./components/text-input/text-input.component";
import { CommonModule } from "@angular/common";
import { ModalComponent } from "./components/modal/modal.component";
import { ToastComponent } from "./components/toast/toast.component";

import { InputDateComponent } from "./components/input-date/input-date.component";
import { MasterPageComponent } from "./components/master-page/master-page.component";
import { PrimaryInputComponent } from "./components/primary-input/primary-input.component";
import { PagComponent } from "./components/pag/pag.component";
import { InformationInputComponent } from './components/information-input/information-input.component';
import { CommonToastsComponent } from './components/common-toasts/common-toasts.component';
import { CalendarModule } from 'primeng/calendar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PrimeInputComponent } from './components/prime-input/prime-input.component';
import { InputTextModule } from 'primeng/inputtext';
@NgModule({
  declarations: [
    TextInputComponent,
    MasterPageLoginComponent,
    ModalComponent,
    ToastComponent,
    InputDateComponent,
    MasterPageComponent,
    PrimaryInputComponent,
    PagComponent,
    InformationInputComponent,
    CommonToastsComponent,
    PrimeInputComponent,
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    TranslateModule,
    RouterModule,
    ListingModule,
    CalendarModule,
    BrowserAnimationsModule,
    InputTextModule,
  ],
  exports: [
    TextInputComponent,
    MasterPageLoginComponent,
    ModalComponent,
    ToastComponent,
    InputDateComponent,
    MasterPageComponent,
    PrimaryInputComponent,
    PagComponent,
    CommonToastsComponent,
    PrimeInputComponent,
  ],
})
export class Sharedmodule {
  constructor(private translate: TranslateService) {
    this.initI18n();
  }
  private initI18n() {
    this.translate.addLangs(['vi']);
    this.translate.setDefaultLang('vi');

    // const browserLang = this.translate.getBrowserLang();
    // if (browserLang && browserLang.match(/vi|en/)) {
    //   this.translate.use(browserLang);
    // }
  }
}
