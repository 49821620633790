<div class="py-[25px] px-[35px] w-auto mt-[20px]">
  <div class="flex justify-center">
    <div class="xl:grid-cols-3 lg:grid-cols-2 grid gap-x-[78px] gap-y-[22px]">
      <div *ngFor="let component of data " (click)="loadComponentWithId(component)"
        [ngClass]="!isHrPortal(component.name)?'hidden':'block'">
        <div class=" w-[368px] h-[130px] bg-white hover:bg-blueBADCEE shadow-lg
          rounded-lg flex pl-[25px] py-[33px] relative cursor-pointer">
          <img [src]=" imgUrl+ component.icon" class="w-12 h-12 mr-[33px] my-auto">
          <span class="border-l-[8px] absolute left-[95px] top-0 h-[130px]"
            [style]="'border-color: '+ component.color"></span>
          <span class="m-auto text-[20px] text-blue font-medium leading-6
            text-center max-w-[150px] ">{{component.name}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>