import { Component } from '@angular/core';
@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.css']
})
export class CheckComponent {
  returnURL = encodeURIComponent("https://gitlab.swerp.com");
  publicKey = encodeURIComponent("YutCM69xjcGkXb2qNzQhmuBoAmeNIepOwG+XK3A1Zh0=")



  
}
