<div
  class="pt-[55px] pl-[90px]"
  [formGroup]="form"
>
  <app-toast
    [statusToast]="statusToast"
    id="notifiChangePass"
    MessToast="{{MessToast}}"
  ></app-toast>
  <p class="text-textblue text-[34px] leading-9 font-semibold max-w-[860px] relative">Đổi mật khẩu</p>
  <div class="pt-10 w-[320px]">
    <app-primary-input
      [isFixed]="true"
      [Primary]="false"
      formControlName="oldPass"
      [formFieldControl]="fields.oldPass"
      [(ngModel)]="txtOldPass"
      [errrorMessages]="FieldErrors"
      [isPassword]="true"
      title="Mật khẩu hiện tại"
      placeholder="Mật khẩu hiện tại"
    ></app-primary-input>
    <div class="pt-[32px] w-[320px]">
      <app-primary-input
        [isFixed]="true"
        [Primary]="false"
        formControlName="newPass"
        [formFieldControl]="fields.newPass"
        [(ngModel)]="txtNewPass"
        [errrorMessages]="FieldErrors"
        [isPassword]="true"
        title="Mật khẩu mới"
        placeholder="Mật khẩu mới"
      ></app-primary-input>
    </div>
    <div class="pt-[32px] w-[320px]">
      <app-primary-input
        [isFixed]="true"
        [Primary]="false"
        formControlName="reNewPass"
        [formFieldControl]="fields.reNewPass"
        [(ngModel)]="txtReNewPass"
        [errrorMessages]="FieldErrors"
        [isPassword]="true"
        title="Nhập lại mật khẩu mới"
        placeholder="Nhập lại mật khẩu mới"
      ></app-primary-input>
    </div>
    <div class="absolute right-[50%] mt-10">
      <button
        class="primary-button"
        (click)="ChangePasss()"
      >
        Lưu
      </button>
    </div>
  </div>
</div>
