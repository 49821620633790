import { Injectable } from "@angular/core";
import { ModalService } from "src/app/shared/components/modal/modal.service";

export interface ToastHandler {
  showCommonToast: (message: string, status: ToastStatus) => void;
}

export type ToastStatus = 'success' | 'error' | 'warning'


@Injectable({
  providedIn: "root",
})
export class CommonToastService {
  private toastHandler?: ToastHandler

  show(message: string, status: ToastStatus = "success") {
    this.toastHandler?.showCommonToast(message, status)
  }

  showError(message: string) {
    this.show(message, "error")
  }

  showWarning(message: string) {
    this.show(message, "warning")
  }

  registerHandler(toastHandler: ToastHandler) {
    this.toastHandler = toastHandler
  }

  removeHandler() {
    this.toastHandler = undefined
  }

}
