import { ForgotPasswordService } from "./../../forgot-password.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Component } from "@angular/core";
import { ErrorMessages } from "src/app/shared/components/text-input/error-messages";
import { TranslateService } from "@ngx-translate/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { ToastService } from "src/app/shared/components/toast/toast.service";
@Component({
  selector: "app-create-newpass",
  templateUrl: "./create-newpass.component.html",
  styleUrls: ["./create-newpass.component.css"],
})
export class CreateNewpassComponent {
  createNewPassword: FormGroup;
  accessToken = "";
  password = "";
  repeatpassword = "";
  check = false;
  statusToast = "";
  MessToast = "";
  constructor(
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private r: ActivatedRoute,
    private router: Router,
    private trans: TranslateService,
    private forgotPasswordService: ForgotPasswordService,
    private toastsv: ToastService
  ) {
    (this.createNewPassword = this.formBuilder.group(
      {
        password: [
          this.password,
          [Validators.required, Validators.minLength(6), rex],
        ],
        repeatPassword: [
          this.repeatpassword,
          [Validators.required, Validators.minLength(6), rex, matchpass],
        ],
      },
      Validators.required
    )),
      (this.accessToken = String(this.r.snapshot.queryParamMap.get("token")));
  }
  get fields() {
    return this.createNewPassword.controls;
  }
  passwordFieldErrors: ErrorMessages = {
    required: () => "Mật khẩu không được để trống",
    minlength: () => "Độ dài mật khẩu phải ít nhất là 6 ký tự",
    rex: () => "Không đúng định dạng mật khẩu",
    matchpass: () => "Không trùng hợp mật khẩu đã nhập",
  };
  back() {
    this.router.navigate(["/login"]);
  }
  createnewpass() {
    if (this.password === this.repeatpassword) {
      this.check = false;
      this.forgotPasswordService
        .changepass(this.accessToken, this.password)
        .subscribe({
          next: () => {
            this.statusToast = "Succeed";
            this.MessToast = "Thay đổi mật khẩu thành công";
            this.openToast(this.statusToast, this.MessToast);
          },
          error: () => {
            this.statusToast = "Failed";
            this.MessToast = "Đã có lỗi xảy ra";
            this.openToast(this.statusToast, this.MessToast);
          },
        });
    } else {
      this.check = true;
    }
  }

  ishidden(): boolean {
    if (this.createNewPassword.valid) return false;
    return true;
  }

  openToast(statusToast: string, MessToast: string, timeout = 3000) {
    this.toastsv.open("notifyCreate");
    setTimeout(() => {
      this.closeToast();
    }, timeout);
  }

  closeToast() {
    this.toastsv.close("notifyCreate");
  }

}

function rex(control: AbstractControl): ValidationErrors | undefined {
  const re = new RegExp(
    "^(?=.*\\d)(?=.*[a-z])(?=.*[@]+.*)(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$"
  );
  if (!re.test(control.value)) {
    return {
      rex: true,
    };
  }
  return undefined;
}

/* function createdoupassmatchvalidator(getpassword:()=>string){
  return function noSpaceValidator(
    control: AbstractControl
  ): ValidationErrors | undefined {let password :string
    try {
      password = getpassword()
      
    } catch (error) {
      password = ''
    }
    if (password !== control.value) {
      return {
        match: true,
      };
    }
    return undefined;
  }
} */

function matchpass(c: AbstractControl): ValidationErrors | undefined {
  if (c.parent?.get("password")?.value !== c.value) {
    return { matchpass: true };
  }
  return undefined;
}


