import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.css'],
})
export class InputDateComponent implements OnInit {
  @Output() getDataEvent = new EventEmitter<Date>();
  @Input() IsDisabled = false;
  date?: Date;
  @Input() control = new FormControl<Date| undefined>(undefined);

  emitValue() {
    this.control.setValue(this.date);
  }
  ngOnInit(): void {
    this.control.valueChanges.subscribe((value) => {
      if (value) {
        this.date = value
      } else {
        this.date = undefined
      }
    });
  }

  // toISODateFormat(dateString: string) {
  //   const isoDateString = moment(dateString, 'MM/DD/YYYY').format('YYYY-MM-DD');
  //   return isoDateString;
  // }
  // getReceivedDate(date: any) {
  //   const localOffset = Date.parse(date);
  //   const zeroUTCOffset = localOffset + new Date().getTimezoneOffset() * 60000;
  //   return new Date(zeroUTCOffset);
  // }
}
