import { LoginService } from './../../../features/login/login.service';
import { ToastService } from 'src/app/shared/components/toast/toast.service';
import { MasterPageService } from './master-page.service';
import { ModalService } from './../modal/modal.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Permission } from 'src/app/features/login/permission.enum';

@Component({
  selector: 'app-master-page',
  templateUrl: './master-page.component.html',
  styleUrls: ['./master-page.component.css'],
})
export class MasterPageComponent implements OnInit {
  imgUrl = `${environment.imgUrl}avatars/`;
  roleUser = Permission;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalsv: ModalService,
    private MasterPageService: MasterPageService,
    private toastsv: ToastService,
    private translate: TranslateService,
    private loginService: LoginService
  ) {}
  user = localStorage.getItem('user');
  userid = '';
  isChecked = 'home';
  get _avatar() {
    return encodeURI(this.avatar);
  }
  ngOnInit(): void {
    this.getUser();
  }

  close() {
    this.profile = false;
  }
  open() {
    this.profile = true;
  }
  check = true;
  menu = true;
  edit = false;
  url = '';
  profile = false;
  openMenu() {
    if (
      this.url === '/changepassword' ||
      this.url === '/profile/' + this.userid
    ) {
      if (this.menu) {
        this.menu = !this.menu;
        this.edit = true;
        this.profile = true;
      } else {
        this.menu = !this.menu;
        this.edit = false;
      }
    } else {
      this.menu = !this.menu;
      this.edit = false;
    }
  }
  openEdit() {
    this.edit = !this.edit;
  }

  logOut(id: string) {
    this.loginService.revokeToken().subscribe(() => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('user');
      localStorage.removeItem('num');
      this.router.navigate(['/login']);
      this.closeModal(id);
    });
  }
  setLocal(value: string) {
    localStorage.setItem('page', value);
  }
  isProfile() {
    if (this.url.includes('profile')) return true;
    else return false;
  }
  isSelected = localStorage.getItem('num') ? localStorage.getItem('num') : '1';
  changeBar() {
    this.check = !this.check;
  }
  selected(num: string) {
    this.isSelected = num;
    localStorage.setItem('num', num.toString());
    if (num === '2') {
      window.location.href = '/UserList/user';
    }
  }
  openModal(id: string) {
    this.modalsv.open(id);
  }
  closeModal(id: string) {
    this.modalsv.close(id);
  }
  avatar = '';
  username = '';
  role = '';
  getUser() {
    this.MasterPageService.getUser().subscribe((data: any) => {
      this.username = data.fullName;
      this.role = data.role;
      this.userid = data.id;
      this.avatar = this.imgUrl + data.avatar;
      console.log(this.avatar);
    });
  }
  oldImg = '';
  file!: File;
  onSelect(event: any) {
    const formData = new FormData();
    this.oldImg = this.avatar;
    this.file = event.target.files[0];
    if (event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      formData.append('avatar', this.file);
      reader.onload = (event: any) => {
        this.avatar = event.target.result;
        this.checkImg = !this.checkImg;
      };
    }
  }
  checkImg = true;
  cancelChange() {
    this.avatar = this.oldImg;
    this.checkImg = !this.checkImg;
  }
  statusToast = '';
  MessToast = '';

  openToast(statusToast: string, MessToast: string, timeout = 3000) {
    this.toastsv.open('notifiMasterPage');
    setTimeout(() => {
      this.closeToast();
    }, timeout);
  }
  closeToast() {
    this.toastsv.close('notifiMasterPage');
  }

  changeImg() {
    this.MasterPageService.uploadAvatar(this.file).subscribe({
      next:(value)=> {
        this.statusToast = 'Succeed';
        this.MessToast = 'Thay đổi ảnh đại diện thành công';
        this.openToast(this.statusToast, this.MessToast);
        this.getUser();
      },
      error: (err) => {
        if (err.status === 200) {
          this.statusToast = 'Succeed';
          this.MessToast = 'Thay đổi ảnh đại diện thành công';
          this.openToast(this.statusToast, this.MessToast);
          this.getUser();
          this.checkImg = !this.checkImg;
        } else {
          this.statusToast = 'Failed';
          this.MessToast = 'Thay đổi ảnh đại diện không thành công';
          this.openToast(this.statusToast, this.MessToast);
          this.getUser();
          this.checkImg = !this.checkImg;
        }
      },
    });
  }
  checkRole() {
    const role = localStorage.getItem('role');
    return role === this.roleUser.SUPPER_ADMIN;
  }
}
