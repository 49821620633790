import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { UserData } from "./use";


@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  apiUrl: string = environment.api;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private http: HttpClient) {}
  createHeader() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken') + '',
    });
    return headers;
  }
  /*   getUser(url: string) {
    const headers = this.createHeader();
    return this.http.get(this.apiUrl + url, {
      headers: headers,
    });
  } */

  getUser(id: string) {
    const headers = this.createHeader();
    return this.http.get(`${this.apiUrl}v1/api/users/${id}`, {
      headers: headers,
    });
  }

  changeStatus(url: string) {
    const headers = this.createHeader();
    return this.http.post(this.apiUrl + url, {
      headers: headers,
    });
  }

  updateUser(user: UserData) {
    const headers = this.createHeader();
    return this.http.put(
      `${this.apiUrl}v1/api/users/${user.id}`,
      {
        address: user.address,
        avatar: user.avatar,
        birthDate: user.birthDate,
        country: user.country,
        email: user.email,
        enabled: user.enabled,
        firstName: user.firstName,
        fullName: user.fullName,
        id: user.id,
        lastName: user.lastName,
        passportNumber: user.passportNumber,
        phone: user.phone,
        role: user.role,
        gender:user.gender
      },
      {
        headers: headers,
      }
    );
  }
  activeOrDeActiveUser(id: string, isActive: boolean) {
    let status = 'acdive'
    if (isActive) {
      status = 'deactivate';
    } else {
      status = 'active';
    }
    const headers = this.createHeader();
    return this.http.post(
      `${this.apiUrl}v1/api/users/${id}/${status}`,'',
      {
        headers: headers,
      }
    );
  }
}
