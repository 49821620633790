import { UserData } from './use';
import { User } from './../login/user';
import { ToastService } from './../../shared/components/toast/toast.service';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { ProfileService } from './profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ErrorMessages } from 'src/app/shared/components/text-input/error-messages';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import * as moment from 'moment';
import { ValidatePattern } from 'src/app/shared/components/prime-input/validate-pattern';
@Component({
  selector: 'app-profile-user',
  templateUrl: './profile-user.component.html',
  styleUrls: ['./profile-user.component.css'],
})
export class ProfileUserComponent implements OnInit {
  status!: boolean;
  isEdit = true;
  lastname = '';
  firstname = '';
  statusToast: 'Succeed' | 'Failed' = 'Succeed';
  MessToast = '';
  checkedSex: 'other' | 'male' | 'female' = 'other';
  data: any;
  edit() {
    this.isEdit = !this.isEdit;
  }
  checkSex(sex: 'other' | 'male' | 'female') {
    this.checkedSex = sex;
  }
  userForm: FormGroup;
  showError = false;
  constructor(
    private route: ActivatedRoute,
    private profilesv: ProfileService,
    private f: FormBuilder,
    private toastsv: ToastService,
    private modalsv: ModalService
  ) {
    this.userForm = this.f.group(
      {
        id: [],
        email: [
          ,
          [
            Validators.required,
            Validators.pattern(ValidatePattern.email.regex),
          ],
        ],
        firstName: [, Validators.required],
        lastName: [, Validators.required],
        fullName: [],
        phone: [, [Validators.required]],
        birthDate: [],
        address: [],
        country: [],
        passportNumber: [, Validators.required],
        avatar: [],
        role: [],
        enabled: [],
        gender: [],
      },
      Validators.required
    );
  }

  getControls(nameControl: string) {
    return this.userForm.controls[nameControl] as FormControl;
  }

  openToast(statusToast: string, MessToast: string, timeout = 3000) {
    this.toastsv.open('notifiProfile');
    setTimeout(() => {
      this.closeToast();
    }, timeout);
  }
  closeToast() {
    this.toastsv.close('notifiProfile');
  }
  FieldErrors: ErrorMessages = {
    required: () => 'Không được để trống',
  };
  userId = localStorage.getItem('userId');
  id: any = '';
  txtdate?: Date;
  txtsex = '';
  txtIdpassport = '';
  txtlastname = '';
  txtFirstName = '';
  txtAddress = '';
  txtPhoneNumber = '';
  txtEmail = '';
  txtCountry = '';
  txtUserId = '';
  avt = '';
  username = '';

  genders = [
    { value: 'Nam', code: 'MALE' },
    { value: 'Nữ', code: 'FEMALE' },
    { value: 'Khác', code: 'OTHER' },
  ];

  checkValueGender(code: string) {
    if (code === this.getControls('gender').value) {
      if (!this.isEdit) {
        return `/assets/icons/toggle-true.svg`;
      } else {
        return `/assets/icons/toggle-check-dis.svg`;
      }
    } else {
      if (!this.isEdit) {
        return `/assets/icons/toggle-false.svg`;
      } else {
        return `/assets/icons/toggle-none-dis.svg`;
      }
    }
  }
  changeGender(code: string) {
    this.getControls('gender').setValue(code);
  }
  ngOnInit(): void {
    this.route.url.subscribe((params) => {
      if (params[0].path === 'user') {
        this.id = this.userId;
        this.getUser(this.userId!);
      } else {
        this.id = params[0].path;
        this.getUser(this.id);
      }
    });
  }

  changeStatus(status: any) {
    this.profilesv.activeOrDeActiveUser(this.id, status).subscribe({
      next: () => {
        this.getUser(this.id);
        if (status) {
          this.MessToast = 'Vô hiệu hóa hoạt tài khoản thành công';
        } else {
          this.MessToast = 'Kích hoạt tài khoản thành công';
        }
        this.statusToast = 'Succeed';
        this.openToast(this.statusToast, this.MessToast);
      },
      error: (err) => {
        this.getUser(this.id);
        if (status) {
          this.MessToast = 'Vô hiệu hóa hoạt tài khoản thất bại';
        } else {
          this.MessToast = 'Kích hoạt tài khoản thất bại';
        }
        this.statusToast = 'Failed';
      },
      complete: () => {
        this.openToast(this.statusToast, this.MessToast);
      },
    });
    this.closeModal('changeStatusAccount');
  }
  getUser(id: string) {
    return this.profilesv.getUser(id).subscribe((data: any) => {
      let formData = {
        ...data,
        birthDate: data.birthDate ? new Date(data.birthDate.replace(/\+[\d:]+/, '')): undefined
      }
      this.userForm.patchValue(formData);
    });
  }

  updateUser() {
    if (this.userForm.invalid) {
      this.showError = true;
    } else {
      let dto = {
        ...this.userForm.value,
        birthDate: this.userForm.value.birthDate ? moment(this.userForm.value.birthDate).toISOString(true) : undefined
      }
      this.profilesv.updateUser(dto).subscribe({
        next: (value) => {
          this.statusToast = 'Succeed';
          this.MessToast = 'Chỉnh sửa người dùng thành công';
          this.openToast(this.statusToast, this.MessToast);
        },
        error: (err) => {
          console.log(err);
          this.statusToast = 'Failed';
          this.MessToast = 'Chỉnh sửa người dùng thất bại';
          this.openToast(this.statusToast, this.MessToast);
        },
        complete: () => {
          this.getUser(this.id);
        },
      });
      this.isEdit = !this.isEdit;
    }
  }
  show(data: any) {
    this.txtdate = data;
  }

  closeModal(id: string) {
    // this.checkSex("other");
    // this.txtdate = "dd/mm/yyyy";
    // this.form.reset();
    this.modalsv.close(id);
  }
  openModal(id: string, lastName: string, firstName: string) {
    this.lastname = lastName;
    this.firstname = firstName;
    this.modalsv.open(id);
  }
  checkUser() {
    return this.id == this.userId;
  }
}
