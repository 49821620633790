import { FormsModule } from '@angular/forms';
import { Sharedmodule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetotpComponent } from './components/getotp/getotp/getotp.component';
import { CreateNewpassComponent } from './components/create-newpass/create-newpass.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateService, TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [ForgotPasswordComponent, GetotpComponent, CreateNewpassComponent],
  imports: [
    CommonModule,RouterModule,Sharedmodule,FormsModule,ReactiveFormsModule,ReactiveFormsModule,TranslateModule
  ],
  exports:[ForgotPasswordComponent,GetotpComponent,CreateNewpassComponent]
})
export class ForgotpasswordModule { 
  constructor(private translate: TranslateService) {
    this.initI18n();
  }
  private initI18n() {
    this.translate.addLangs(["en", "vi"]);
    this.translate.setDefaultLang("vi");
    
    const browserLang = this.translate.getBrowserLang();
    if (browserLang && browserLang.match(/vi|en/)) {
      this.translate.use(browserLang);
    }
  }
}
