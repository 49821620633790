import { ToastService } from "./../../shared/components/toast/toast.service";
import { ChangepaswwService } from "./changepasww.service";
import { TranslateService } from "@ngx-translate/core";
import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { ErrorMessages } from "src/app/shared/components/text-input/error-messages";
import { Router } from "@angular/router";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  form: FormGroup;
  txtOldPass = '';
  txtNewPass = '';
  txtReNewPass = '';
  constructor(
    private f: FormBuilder,
    private translate: TranslateService,
    private changepassSv: ChangepaswwService,
    private toastsv: ToastService,
    private router: Router
  ) {
    this.form = this.f.group({
      oldPass: [
        this.txtOldPass,
        [Validators.required, Validators.minLength(6), rex],
      ],
      newPass: [
        this.txtNewPass,
        [Validators.required, Validators.minLength(6), rex],
      ],
      reNewPass: [
        this.txtReNewPass,
        [Validators.required, Validators.minLength(6), rex, matchpass],
      ],
    });
  }
  ngOnInit(): void {
    document.body.style.backgroundColor = 'white';
  }
  get fields() {
    return this.form.controls;
  }
  openToast(timeout = 3000) {
    this.toastsv.open('notifiChangePass');
    setTimeout(() => {
      this.closeToast();
    }, timeout);
  }
  closeToast() {
    this.toastsv.close('notifiChangePass');
  }

  ChangePasss() {
    this.changepassSv.changepass(this.txtNewPass, this.txtOldPass).subscribe({
      next: () => {
        this.statusToast = 'Succeed';
        this.MessToast = 'Đổi mật khẩu thành công';
        this.openToast();
        localStorage.removeItem('accessToken');
        this.router.navigate(['/login']);
      },
      error: (err) => {
        this.statusToast = 'Failed';
        this.MessToast = 'Đổi mật khẩu thất bại';
        this.openToast();
        this.openToast();
      },
    });
  }
  statusToast = '';
  MessToast = '';
  FieldErrors: ErrorMessages = {
    required: () => 'Mật khẩu không được để trống',
    minlength: () => 'Độ dài mật khẩu phải có độ dài tối thiểu là 6 ký tự',
    rex: () => 'Mật khẩu không đúng định dạng',
    matchpass: () => 'Mật khẩu nhập lại không trùng khớp',
  };
}
function rex(control: AbstractControl): ValidationErrors | undefined {
  const re = new RegExp(
    "^(?=.*\\d)(?=.*[a-z])(?=.*[@]+.*)(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$"
  );
  if (!re.test(control.value)) {
    return {
      rex: true,
    };
  }
  return undefined;
}

function matchpass(c: AbstractControl): ValidationErrors | undefined {
  if (c.parent?.get("newPass")?.value !== c.value) {
    return { matchpass: true };
  }
  return undefined;
}
