import { ToastService } from "src/app/shared/components/toast/toast.service";
import { ForgotPasswordService } from "./../../forgot-password.service";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ErrorMessages } from "src/app/shared/components/text-input/error-messages";
import { TranslateService } from "@ngx-translate/core";

function noSpaceValidator(
  control: AbstractControl
): ValidationErrors | undefined {
  const value: string = control.value;
  if (value.match(/\s/)) {
    return {
      spaces: true,
    };
  }
  return undefined;
}

function noEmail(control: AbstractControl): ValidationErrors | undefined {
  if (!control.value.includes("@outlook.com.vn")) {
    return {
      noemail: true,
    };
  }
  return undefined;
}
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent {
  email = '';
  animation = 'animate-none';
  statusToast = '';
  MessToast = '';
  form: FormGroup;
  constructor(
    private r: Router,
    private f: FormBuilder,
    private trans: TranslateService,
    private forgotPasswordService: ForgotPasswordService,
    private toastsv: ToastService
  ) {
    this.form = f.group(
      {
        e: [this.email, [Validators.required, noSpaceValidator]],
      },
      Validators.required
    );
  }
  get fields() {
    return this.form.controls;
  }
  back() {
    this.r.navigate(['/login']);
  }
  ishidden(): boolean {
    if (this.form.valid) return false;
    return true;
  }
  pulse = false;
  log() {
    this.pulse = true;
    this.forgotPasswordService.checkemail(this.email).subscribe({
      next: () => {
        this.pulse = false;
        this.statusToast = 'Succeed';
        this.MessToast = 'Vui lòng đến Email để tiến hành lấy lại mật khẩu';
        this.openToast(this.statusToast, this.MessToast);
      },
      error: (err) => {
        this.pulse = false;
        if (err.status === 404) {
          this.statusToast = 'Failed';
          this.MessToast = 'Không tìm thấy Email trong hệ thống';
          this.openToast(this.statusToast, this.MessToast);
        } else {
          this.statusToast = 'Failed';
          this.MessToast = 'Lỗi chưa xác định';
          this.openToast(this.statusToast, this.MessToast);
        }
      },
    });
  }
  openToast(statusToast: string, MessToast: string, timeout = 3000) {
    this.toastsv.open('notififorgot');
    setTimeout(() => {
      this.closeToast();
    }, timeout);
  }
  closeToast() {
    this.toastsv.close('notififorgot');
  }

  textFieldErrors: ErrorMessages = {
    required: () => 'Email không được để trống',
    spaces: () => 'Email không được xuất hiện khoảng trống',
    noemail: () => 'Không đúng định dạng Email',
  };
}
