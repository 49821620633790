export class IdGenerator {
  private static instances: {
    [index: string]: IdGenerator;
  } = {};
  name: string;
  private count = 0;
  private constructor(name: string) {
    this.name = name;
  }
  static of(name: string): IdGenerator {
    if (!this.instances[name]) {
      this.instances[name] = new IdGenerator(name);
    }
    return this.instances[name];
  }
  next(): string {
    const result = `${this.name} ${this.count}`;
    this.count = this.count + 1;
    return result;
  }
}
