export class ValidatePattern {
  public static number = {
    regex: /^[0-9]*$/,
    errorMessage: 'error.number',
  };
  public static naturalNumber = {
    regex: /^\d*$/,
    errorMessage: 'error.naturalNumber',
  };
  public static positiveDecimal = {
    regex: /^(\d*[1-9]+\d*)?((\d*[1-9]+\d*\.\d+)|(\d+\.\d*[1-9]+\d*))?$/,
    errorMessage: 'error.positiveDecimal',
  };
  public static hasLetter = {
    regex: /[a-zA-Z]/,
    errorMessage: 'error.hasLetter',
  };
  public static email = {
    regex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    errorMessage: 'error.email',
  };
  public static phone = {
    regex: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
    errorMessage: 'error.phone',
  };
}
