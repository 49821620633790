<div
  class="lg:flex font-Mulish w-full h-full lg:bg-white "
  [formGroup]="createNewPassword"
>
<app-toast
  id="notifyCreate"
  class=" "
  statusToast="{{statusToast}}"
  MessToast="{{MessToast}}"
></app-toast>

  <div class="lg:w-[40%] w-full lg:bg-white  h-auto bg-textblue ">
    <div class="bg-textblue text-center pt-10 px-28 text-white w-full h-[12rem] lg:hidden">
      <img
        src="/assets/SSO.png"
        class="m-auto"
      >
      <p class="border-t-2 border-white pt-3 mt-4 text-base leading-6 font-semibold">Enterprise Resource Planning system</p>
    </div>
    <div class=" bg-white  lg:max-w-[30rem] h-auto
     lg:h-[448px] pt-4 lg:pt-[10rem] xl:pt-[20rem] mb-[175px] m-auto px-5 rounded-t-3xl">
      <div>
        <p class=" text-base leading-6 font-semibold">Mật khẩu mới</p>
        <p class="text-[30px] font-bold leading-9 mt-3">Tạo mật khẩu mới!</p>
      </div>
      <div>
        <div class=" mt-10 ">
          <app-primary-input
            formControlName="password"
            [formFieldControl]="fields.password"
            [(ngModel)]="password"
            [errrorMessages]="passwordFieldErrors"
            title="Mật khẩu mới"
            [isPassword]="true"
            placeholder="Mật khẩu mới"
          >
          </app-primary-input>
        </div>
        <div class="mt-5 ">
          <app-primary-input
            formControlName="repeatPassword"
            [formFieldControl]="fields.repeatPassword"
            [(ngModel)]="repeatpassword"
            [errrorMessages]="passwordFieldErrors"
            title="Nhập lại mật khẩu mới"
            [isPassword]="true"
            placeholder="Nhập lại mật khẩu mới"
          >
          </app-primary-input>
        </div>
      </div>
      <div class="text-center mt-10 pb-10">
        <button class="bg-primaryYellowe text-[20px] py-[16px] w-full text-white rounded uppercase" (click)="createnewpass()">Lưu</button>
        <div class=" justify-center leading-6 text-[1.25rem] flex font-normal pt-9">
          <p>Trở về</p>
          <span class="w-1"></span>
          <a
            routerLink="/login"
            class="w-fit cursor-pointer text-primaryYellowe "
          >
           Đăng nhập
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="lg:block hidden justify-center w-[60%] h-full relative text-center bottom-0 flex-shrink">
    <img
      src="/assets/img/login.png"
      class="lg:min-h-[805px] h-full w-full bg-cover aspect-[4/3]"
    >
    <div class="absolute top-[50%] left[50%] -translate-y-1/2 w-full">
      <div class="text-white text-center font-bold leading-tight text-9xl h-fit w-full">SSO</div>
      <div class="border border-white border-solid mx-28"></div>
      <div class="mt-11 text-white w-full leading-9 text-[2.125rem]">Enterprise Resource Planning System</div>
    </div>
  </div>
  <app-toast
    id="notifyCreate"
    class=" "
    statusToast="{{statusToast}}"
    MessToast="{{MessToast}}"
  ></app-toast>
</div>
