import { environment } from 'src/environments/environment';
import { ListingService } from './../listing.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ComponentUrlEnum } from '../componentUrl.enum';
import { Permission } from '../../login/permission.enum';
@Component({
  selector: 'app-listing-components',
  templateUrl: './listing-components.component.html',
  styleUrls: ['./listing-components.component.css'],
})
export class ListingComponentsComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private listingService: ListingService
  ) {}
  data: any;
  componentUrl = ComponentUrlEnum;
  roleUser = Permission;
  imgUrl = `${environment.imgUrl}icons/`;
  ngOnInit(): void {

    console.log(Object.keys(this.componentUrl));
    document.body.style.backgroundColor = 'white';
    this.listingService.getComponents().subscribe((data) => {
      this.data = data;
    });
  }

  loadComponentWithId(component: any) {
    /*  const role = localStorage.getItem('role');
    if (role === this.roleUser.SUPPER_ADMIN) {
      window.location.href = 'UserList/' + component.id;
      localStorage.removeItem('num');
    } else {
      window.location.href = this.componentUrl[component.name];
    } */
    window.location.href = this.componentUrl[component.name];
  }
  isHrPortal(nameComponent: any) {
    const role = localStorage.getItem('role');
    if (role === this.roleUser.USER && nameComponent === this.componentUrl.HR) {
      return false;
    }
    return true;
  }
}
