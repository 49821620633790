<div class="pt-[100px] flex  justify-center">
  <div class="align-middle text-center  m-auto">
    <img src="/assets/img/404 Text.png">
    <p class="text-[48px] mt-[50px]">Rất tiếc, không thể tìm thấy trang này.</p>
    <div class="mt-[50px] text-[24px] text-textblue">
      <p>
        Trang bạn đang tìm kiếm có thể đã bị xóa
      </p>
      <p>hoặc tạm thời không có sẵn.</p>
    </div>
    <div class="mt-[70px]">
      <input
        class="bg-textblue text-white px-[70px] py-[20px] rounded-full cursor-pointer"
        type="button"
        value="Quay về trang chủ "
        (click)="click()"
      >
    </div>
  </div>
</div>
