import { FormControl } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { ValidatePattern } from './validate-pattern';
import { inputs } from '@syncfusion/ej2-angular-popups/src/tooltip/tooltip.component';

@Component({
  selector: 'app-prime-input',
  templateUrl: './prime-input.component.html',
  styleUrls: ['./prime-input.component.css'],
})
export class PrimeInputComponent {
  @Input() title!: string;
  @Input() disabled = false;
  @Input() control = new FormControl();
  @Input() showError = false;
  @Input() isRequire = false;
  pattern = ValidatePattern;
  patternError() {
    if (this.control.errors?.['required']) {
      this.control.setErrors;
      return 'error.require';
    }
    if (this.control.errors?.['email']) {
      return 'error.email';
    }
    return (
      ValidatePattern[
        Object.keys(ValidatePattern).find(
          (x) =>
            ValidatePattern[x as keyof ValidatePattern]['regex'] ==
            this.control.errors?.['pattern']?.requiredPattern
        ) as keyof ValidatePattern
      ]['errorMessage'] ?? ''
    );
  }
}
