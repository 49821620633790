<div #formInput>
  <div class>
    <div class="w-full checked:text-blue ">
     <!-- <label
        class=" text-red text-[14px] leading-5 font-semibold"
        *ngIf="touch && firstErrorMessage && firstErrorMessage?.length !== 0"
      >
        {{title}}
      </label> -->      
      <label
        for="text"
        class="{{firstErrorMessage && showError  && firstErrorMessage.length !== 0 ? 'text-red' :'text-black'}} text-[14px] leading-5 font-semibold "
        *ngIf="!IsReadonly && !Isdisabled"
      >
        {{ title | translate }}
        <span
          *ngIf="title && Primary"
          class="text-red"
        >
          *
        </span>
      </label>
      <label
        for="text"
        class="text-textgray text-[14px] leading-5 font-semibold "
        *ngIf="IsReadonly || Isdisabled"
      >
        {{ title | translate }}
      </label>
      <div class="relative">
        <input
          type="text"
          class=" block w-full h-[50px] py-[10px] px-[12px] rounded-lg border border-linegray  text-textblue text-[16px] leading-6 tracking-wide font-semibold
       focus:bg-white focus:text-black  {{(firstErrorMessage && showError )? 'border-red text-black':'border-linegray'}} focus:outline-none disabled:text-scgray {{IsReadonly?'focus:border-linegray':'focus:border-textblue'}}"
          [(ngModel)]="value"
          on-focus="focusFunction()"
          on-focusout="focusOutFunction()"
          [readonly]="IsReadonly"
          placeholder="{{placeholder | translate}}"
          disabled="{{Isdisabled}}"
          [type]="inputType"
          (keydown.enter)="enter()"
          
        >
        <div
          class="cursor-pointer flex items-center border-none absolute inset-y-0  pr-3 right-0 leading-5"
          *ngIf="isPassword"
          (click)="toggleEyeIcon()"
        >
          <svg class="{{ hidePassword ? ' fill-scgray':' fill-blue'}}"  width="20" height="20" viewBox="0 0 20 20"  xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.999 4.166c-4.167 0-7.725 2.592-9.167 6.25 1.442 3.658 5 6.25 9.167 6.25 4.166 0 7.725-2.592 9.166-6.25-1.441-3.658-5-6.25-9.166-6.25zm0 10.417a4.168 4.168 0 0 1-4.167-4.167c0-2.3 1.867-4.167 4.167-4.167s4.166 1.867 4.166 4.167S12.3 14.583 10 14.583zm0-6.667a2.497 2.497 0 0 0-2.5 2.5c0 1.383 1.116 2.5 2.5 2.5 1.383 0 2.5-1.117 2.5-2.5s-1.117-2.5-2.5-2.5z"  />
          </svg>
        </div>
      </div>
    </div>
  </div>
  <span
    class="absolute text-invalid font-semibold text-[12px] flex {{isFixed?'fixed':''}}"
    *ngIf="firstErrorMessage && showError "
  >
  {{firstErrorMessage}}
   
  </span>
</div>
