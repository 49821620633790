import { Component, NgZone, OnInit } from '@angular/core';
import { ToastStatus, CommonToastService } from '../../services/common-toast.service';
import { ModalService } from '../modal/modal.service';
import { IdGenerator } from './modal-id-generator';

@Component({
  selector: 'app-common-toasts',
  templateUrl: './common-toasts.component.html',
  styleUrls: ['./common-toasts.component.css'],
})
export class CommonToastsComponent implements OnInit {
  toastData: Array<{
    message: string;
    status: ToastStatus;
    id: string;
  }> = [];

  idGenerator = IdGenerator.of('CommonToast');

  constructor(
    private modalService: ModalService,
    private commonToastService: CommonToastService,
    private ngZone: NgZone
  ) {}

  showCommonToast = (message: string, status: ToastStatus) => {
    const toast = {
      message,
      status,
      id: this.idGenerator.next(),
    };

    this.toastData = [toast, ...this.toastData];

    setTimeout(() => {
      this.modalService.close(toast.id);
      this.toastData = this.toastData.slice(0, this.toastData.length - 1);
    }, 3000);
  };

  getToastsData() {
    return this.toastData;
  }

  ngOnInit(): void {
    this.commonToastService.registerHandler(this);
  }

  ngOnDestroy(): void {
    this.commonToastService.removeHandler();
  }
}
