import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ForgotPasswordService {
  apiurl: string = environment.api;
  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(private http: HttpClient) {}

  createHeader() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("accessToken") + "",
    });
    return headers;
  }
  checkemail(email: string) {
    return this.http.post(this.apiurl + "password/forgot", { email: email });
  }
  changepass(token: string, newpass: string) {
    return this.http.post(this.apiurl + "password/reset", {
      password: newpass,
      resetPasswordToken: token,
    });
  }
  addemail(email: string) {
    const header = this.createHeader();
    return this.http.post(
      this.apiurl + "password/forgot",
      { email: email },
      {
        headers: header,
      }
    );
  }
}
