import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Userdetail } from "../listing-user/userdetail";
import { UserData } from "../profile-user/use";

@Injectable({
  providedIn: 'root',
})
export class UserListingService {
  apiUrl: string = environment.api;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private http: HttpClient) {}
  createHeader() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken') + '',
    });
    return headers;
  }
  getUser(url: string) {
    console.log(this.apiUrl + url);
    const headers = this.createHeader();
    return this.http.get(this.apiUrl + url, {
      headers: headers,
    });
  }

  deleteUserInComponent(id: string,idUser:string) {
    const headers = this.createHeader();
    return this.http.delete(`${this.apiUrl}v1/api/components/${id}/users`, {
      headers: headers,
      body: {
        userIdList: [idUser],
      },
    });
  }
  deleteUser(id: string) {
    const headers = this.createHeader();
    return this.http.delete(`${this.apiUrl}v1/api/users/${id}`, {
      headers: headers
    
    });
  }
  searchUser(name: string) {
    const headers = this.createHeader();
    return this.http.get(this.apiUrl + name, {
      headers: headers,
    });
  }
  addUser(user: UserData) {
    const headers = this.createHeader();
    return this.http.post(
      this.apiUrl + 'v1/api/users',
      {
        address: user.address,
        birthDate: user.birthDate,
        country: user.country,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        passportNumber: user.passportNumber,
        phone: user.phone,
        gender: user.gender,
      },
      {
        headers: headers,
      }
    );
  }

  addUseInComponent(componentId: string, userId: string[]) {
    const headers = this.createHeader();
    return this.http.post(
      `${this.apiUrl}/v1/api/components/${componentId}/users`,
      {
        userIdList: userId,
      },
      {
        headers: headers,
      }
    );
  }
}
