<div [formGroup]="form"
  class="bg-[url('/assets/img/login.avif')] px-2 md:mx-0 pt-[20%] lg:pt-[15%]  font-Mulish object-fill w-full h-fit min-h-[100vh] pb-[5%] bg-no-repeat bg-cover bg-center">
  <div class="md:w-[27.5rem] h-auto m-auto rounded-lg bg-white  p-10">
    <img src="/assets/img/login-logo.png" class="m-auto w-[7.75rem] h-[7.75rem]">
    <p class="text-center font-bold text-2xl mt-6">Quên mật khẩu?</p>
          <div class=" mt-6">
            <app-primary-input formControlName="e" [formFieldControl]="fields.e" [(ngModel)]="email"
              [errrorMessages]="textFieldErrors" title="Email" [Primary]="false" placeholder="Email">
            </app-primary-input>
          </div>
            <p class="mt-3 text-base font-semibold">
              Vui lòng nhập địa chỉ email để nhận mã xác minh
            </p>
                <div class="text-center mt-6 pb-2">
                  <button (click)="log()" [disabled]="pulse"
                    class="bg-[#3385FF] text-sm lg:text-base py-4 w-full text-white rounded uppercase shadow-lg ">
                    Gửi </button>
                </div>
                <div class=" justify-center leading-6 text-[1.25rem] flex font-normal mt-4">
                  <p class="pr-1">Trở về</p>
                  <span class="w-1"></span>
                  <a routerLink="/login" class="w-fit cursor-pointer text-[#3385FF] ">
                    Đăng nhập
                  </a>
  </div>
    <app-toast id="notififorgot" class=" " statusToast="{{statusToast}}" MessToast="{{MessToast}}"></app-toast>
</div>

<!--  <div
  class="lg:flex font-Mulish w-full h-full lg:bg-white "
  [formGroup]="form"
>
  <div class="lg:w-[40%] w-full lg:bg-white  h-auto bg-textblue ">
    <div class="bg-textblue text-center pt-10 px-28 text-white w-full h-[12rem] lg:hidden">
      <img
        src="/assets/SSO.png"
        class="m-auto"
      >
      <p class="border-t-2 border-white pt-3 mt-4 text-base leading-6 font-semibold">Enterprise Resource Planning system</p>
    </div>
    <div class=" bg-white  lg:max-w-[30rem] h-auto
     lg:h-[448px] pt-4 lg:pt-[10rem] xl:pt-[20rem] mb-[175px] m-auto px-5 rounded-t-3xl">
      <div>
        <p class=" text-base leading-6 font-semibold">  Quên mật khẩu?  </p>
        <p class="text-[30px] font-bold leading-9 mt-3"> Vui lòng nhập Email!</p>
      </div>
      <div>
        <div class=" mt-10 ">
          <app-primary-input
            formControlName="e"
            [formFieldControl]="fields.e"
            [(ngModel)]="email"
            [errrorMessages]="textFieldErrors"
            title="Email"
            [Primary]="false"
            placeholder="Email"
          >
          </app-primary-input>
        </div>
        <p class="mt-3 text-base font-normal ">
          Vui lòng nhập địa chỉ email để nhận mã xác minh
        </p>
      </div>
      <div class="text-center mt-10 pb-2">
        <button (click)="log()" class="bg-primaryYellowe text-[20px] py-[16px] w-full text-white rounded uppercase shadow-lg "> Gửi </button>
      </div>
      <div class=" justify-center leading-6 text-[1.25rem] flex font-normal mt-4">
        <p class="pr-1">Trở về</p>
        <span class="w-1"></span>
        <a
          routerLink="/login"
          class="w-fit cursor-pointer text-primaryYellowe "
        >
        Đăng nhập
        </a>
      </div>
    </div>
  </div>
  <div class="lg:block hidden justify-center w-[60%] h-full relative text-center bottom-0 flex-shrink">
    <img
      src="/assets/img/login.png"
      class="lg:min-h-[805px] h-full w-full bg-cover aspect-[4/3]"
    >
    <div class="absolute top-[50%] left[50%] -translate-y-1/2 w-full">
      <div class="text-white text-center font-bold leading-tight text-9xl h-fit w-full">SSO</div>
      <div class="border border-white border-solid mx-28"></div>
      <div class="mt-11 text-white w-full leading-9 text-[2.125rem]">Enterprise Resource Planning System</div>
    </div>
  </div>
  <app-toast
    id="notififorgot"
    class=" "
    statusToast="{{statusToast}}"
    MessToast="{{MessToast}}"
  ></app-toast>
</div> -->
