<div
  class="xl:max-w-[1024px] xxl:max-w-[1366px] w-full flex mx-auto pr-[200px] font-Mulish"
>
      <div class="min-w-[280px] max-w-[330px] flex-col">
        <div class="w-[900px] py-[47px] px-8 justify-center">
        <div 
        class="relative">
        <div class="pb-10">
          <p class="text-textblue text-[34px] leading-9"> Hồ sơ nhân viên</p>
        </div>

        <div class=" w-full flex justify-end cursor-pointer {{isEdit ? ' opacity-100' : 'opacity-0'}}">
    <!--       <input
          [disabled]="!isEdit"
            type="button"
            class=" font-semibold {{isEdit ? ' primary-button' : 'primary-button-disable'}}"
            value="Chỉnh sửa"
            (click)="edit()"
          > -->
                    <div  class=" stroke-textblue rounded-l-xl pl-2 py-2 mt-5 flex justify-end cursor-pointer"  (click)="edit()">
                      <svg class="w-10 h-10 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.474 5.40783L18.592 7.52483M17.836 3.54283L12.109 9.26983C11.8131 9.56532 11.6113 9.94181 11.529 10.3518L11 12.9998L13.648 12.4698C14.058 12.3878 14.434 12.1868 14.73 11.8908L20.457 6.16383C20.6291 5.99173 20.7656 5.78742 20.8588 5.56256C20.9519 5.33771 20.9998 5.09671 20.9998 4.85333C20.9998 4.60994 20.9519 4.36895 20.8588 4.14409C20.7656 3.91923 20.6291 3.71492 20.457 3.54283C20.2849 3.37073 20.0806 3.23421 19.8557 3.14108C19.6309 3.04794 19.3899 3 19.1465 3C18.9031 3 18.6621 3.04794 18.4373 3.14108C18.2124 3.23421 18.0081 3.37073 17.836 3.54283V3.54283Z"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path
                          d="M19 15V18C19 18.5304 18.7893 19.0391 18.4142 19.4142C18.0391 19.7893 17.5304 20 17 20H6C5.46957 20 4.96086 19.7893 4.58579 19.4142C4.21071 19.0391 4 18.5304 4 18V7C4 6.46957 4.21071 5.96086 4.58579 5.58579C4.96086 5.21071 5.46957 5 6 5H9"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg>
                    </div>
        </div>

          <div class="grid md:grid-cols-2 md:gap-6 pt-20 ">
            <div class="relative z-0 mb-6 w-[326px] group">
       <!--        <app-primary-input
              title="Họ"
              placeholder="Họ"
              formControlName="lastname"
              [formFieldControl]="fields.lastname"
              [errrorMessages]="FieldErrors"
              [(ngModel)]="txtlastname"
              [isFixed]="true"
              [Isdisabled]="isEdit"
            ></app-primary-input> -->
              <app-prime-input title="Họ" [control]="getControls('lastName')" [disabled]="isEdit" [showError]="showError" [isRequire]="true"> 
              
              </app-prime-input>
            </div>
        
            <div class="relative z-0 mb-6 w-[326px] group">
            <!--       <app-primary-input
                    [isFixed]="true"
                    title="Tên"
                    placeholder="Tên"
                    [errrorMessages]="FieldErrors"
                    [formFieldControl]="fields.firstname"
                    formControlName="firstname"
                    [(ngModel)]="txtFirstName"
                    [Isdisabled]="isEdit"
                  ></app-primary-input> -->
                    <app-prime-input title="Tên" [control]="getControls('firstName')" [disabled]="isEdit" [showError]="showError" [isRequire]="true">
                    
                    </app-prime-input>
              </div>
              
          </div>
        
          <div class="grid md:grid-cols-2 md:gap-6">
              <div class="relative z-0 mb-6 w-[326px] group">
           <!--      <app-primary-input
                [isFixed]="true"
                formControlName="email"
                [formFieldControl]="fields.email"
                [(ngModel)]="txtEmail"
                [errrorMessages]="FieldErrors"
                title="Email"
                placeholder="Email"
                [Isdisabled]="isEdit"
              ></app-primary-input> -->
                <app-prime-input title="Email" [control]="getControls('email')" [disabled]="true" [showError]="showError" [isRequire]="true">
                
                </app-prime-input>
              </div>
        
              <div class="relative z-0 mb-6 w-[326px] group">
              <!--   <app-primary-input
                [Isdisabled]="true"
                title="ID nhân viên"
                placeholder="ID nhân viên"
              ></app-primary-input> -->
                <app-prime-input title="ID nhân viên" [disabled]="true">
                
                </app-prime-input>
              </div>
          </div>
        
          <div class="grid md:grid-cols-2 md:gap-6">
              <div class="relative z-50 mb-6 w-[326px] group">
                <app-input-date
                [control]="getControls('birthDate')"
                (getDataEvent)="show($event)"
                [IsDisabled]="isEdit"
                >
                </app-input-date>
              </div>
              
              <div class="relative z-0 mb-6 w-[326px] group">
                <label class=" {{!isEdit?'text-textblue':'text-linegray'}}  font-semibold  text-[14px] leading-5" >Giới tính</label>
                  <div class="flex justify-between mt-4">
                    <span  *ngFor="let item of genders" class="flex">
                      <img [src]="checkValueGender(item.code)" class="mx-2 {{!isEdit?'cursor-pointer':'cursor-default'}}" (click)="changeGender(item.code)">
                      <span class="cursor-default {{!isEdit?'text-textblue':'text-linegray'}}">{{item.value}}</span>
                    </span>  
                  </div>
       <!--          <div
                  class="flex justify-between mt-4 text-scgray"
                  *ngIf="isEdit">
                  <div class="flex">
                    <img
                      src="{{checkedSex==='male'?'/assets/icons/toggle-check-dis.svg':'/assets/icons/toggle-none-dis.svg'}}"
                      class="mx-2"
                    >
                    <p>Nam</p>
                  </div>
                  <div class="flex">
                    <img
                      src="{{checkedSex==='female' ?'/assets/icons/toggle-check-dis.svg':'/assets/icons/toggle-none-dis.svg'}}"
                      class="mx-2"
                    >
                    <p>Nữ</p>
                  </div>
                  <div class="flex">
                    <img
                      src="{{ checkedSex === 'other'? '/assets/icons/toggle-check-dis.svg':'/assets/icons/toggle-none-dis.svg'}}"
                      class="mx-2"
                    >
                    <p>Khác</p>
                  </div>
                </div> -->

<!--                 <div
                    class="flex justify-around mt-4 text-textblue"
                    *ngIf="!isEdit"
                  >
                    <div class="flex">
                      <img
                        src="{{checkedSex==='male'?'/assets/icons/toggle-true.svg':'/assets/icons/toggle-false.svg'}}"
                        (click)=" checkSex('male')"
                        class="mx-2"
                      >
                      <p>Nam</p>
                    </div>
                    <div class="flex">
                      <img
                        src="{{checkedSex==='female' ?'/assets/icons/toggle-true.svg':'/assets/icons/toggle-false.svg'}}"
                        (click)="checkSex('female')"
                        class="mx-2"
                      >
                      <p>Nữ</p>
                    </div>
                    <div class="flex">
                      <img
                        src="{{ checkedSex === 'other'? '/assets/icons/toggle-true.svg':'/assets/icons/toggle-false.svg'}}"
                        (click)="checkSex('other')"
                        class="mx-2"
                      >
                      <p>Khác</p>
                    </div>
              </div> -->
            </div>
          </div>
          <div class="grid md:grid-cols-2 md:gap-6">
            <div class="relative z-0 mb-6 w-[326px] group">
           <!--    <app-primary-input
                  [isFixed]="true"
                  formControlName="idpassport"
                  [errrorMessages]="FieldErrors"
                  [formFieldControl]="fields.idpassport"
                  title="Số CMND/ CCCD/ Hộ chiếu"
                  placeholder="Số CMND/ CCCD/ Hộ chiếu"
                  [Isdisabled]="isEdit"
                  [(ngModel)]="txtIdpassport"
                ></app-primary-input> -->
                  <app-prime-input title="Số CMND/ CCCD/ Hộ chiếu" [showError]="showError" [isRequire]="true" [control]="getControls('passportNumber')" [disabled]="isEdit">
                  
                  </app-prime-input>
            </div>
            <div class="relative z-0 mb-6 w-[326px]  group">
          <!--     <app-primary-input
                [isFixed]="true"
                [Primary]="false"
                [formFieldControl]="fields.country"
                [errrorMessages]="FieldErrors"
                formControlName="country"
                [(ngModel)]="txtCountry"
                title="Quốc tịch"
                placeholder="Quốc tịch"
                [Isdisabled]="isEdit"
              ></app-primary-input> -->
                <app-prime-input title="Quốc tịch" [control]="getControls('country')" [disabled]="isEdit">
                
                </app-prime-input>
              </div>
            </div>
      
            <div class="grid md:grid-cols-2 md:gap-6">
              <div class="relative z-0 mb-6 w-[326px]">
             <!--    <app-primary-input
                  [isFixed]="true"
                  formControlName="phonenumber"
                  [errrorMessages]="FieldErrors"
                  [formFieldControl]="fields.phonenumber"
                  [(ngModel)]="txtPhoneNumber"
                  title="Số điện thoại"
                  placeholder="Số điện thoại"
                  [Isdisabled]="isEdit"
                ></app-primary-input> -->
                  <app-prime-input title="Số điện thoại" [control]="getControls('phone')" [isRequire]="true"  [disabled]="isEdit" [showError]="showError">
                  
                  </app-prime-input>
              </div>
              <div class="relative z-0 mb-6 w-[326px]">
            <!--     <app-primary-input
                [isFixed]="true"
                [Primary]="false"
                [(ngModel)]="txtAddress"
                formControlName="address"
                [errrorMessages]="FieldErrors"
                [formFieldControl]="fields.address"
                title="Địa chỉ"
                placeholder="Địa chỉ"
                [Isdisabled]="isEdit"
              ></app-primary-input> -->
              <app-prime-input title="Địa chỉ" [control]="getControls('address')" [disabled]="isEdit">

              </app-prime-input>
                </div>
              </div>

        </div>        
        
    <div class="flex  {{checkUser()?'justify-end ':'justify-between'}} ">
      <input
       *ngIf="!checkUser()"
        type="button"
        class="w-[120px] cursor-pointer text-[16px] leading-6 font-semibold tracking-wider h-[50px] rounded-[16px] {{getControls('enabled').value?'text-red bg-reddeactive':'text-textgre bg-greenactive'}} "
        value="{{getControls('enabled').value?'Vô hiệu hóa' : 'Kích hoạt'}}"
        (click)="openModal('changeStatusAccount', txtlastname,txtFirstName)"
      >
      <input
        [disabled]="isEdit"
        type="button"
        class=" w-[120px]  primary-button {{!isEdit ? ' opacity-100' : 'opacity-0'}}"
        value="Lưu"
        (click)="updateUser()"
      >
    </div>
  </div>
  <app-modal id="changeStatusAccount">
    <div class="relative z-100 pt-[30px] w-[450px] pb-7 text-center m-auto font-Mulish">
        <div class="flex justify-between px-7 mb-2">
          <span class="{{getControls('enabled').value?' text-redtextremove' : 'text-textgre'}} text-2xl"> {{getControls('enabled').value?'Vô hiệu hóa' : 'Kích hoạt'}} hóa tài khoản</span>
          <img
            class="h-5 w-5 cursor-pointer"
            src="/assets/icons/close.svg"
            (click)="closeModal('changeStatusAccount')"
          >
        </div>
        <p class="border border-linegray"></p>
        <div class="px-7 mb-2 flex-col text-left font-semibold">
          <p  class="mt-[20px]"> Bạn có chắc chắn muốn {{getControls('enabled').value?'vô hiệu hóa' : 'kích hoạt'}} tài khoản {{lastname}} {{firstname}}? </p>
        </div>
        
        <div class="pt-6 px-7 mb-2 flex justify-between font-semibold ">
          <input
            class="bg-whitef text-buttongray py-[4px] px-[24px] w-[140px] rounded-lg border border-buttongray cursor-pointer "
            type="button"
            value="Hủy"
            (click)="closeModal('changeStatusAccount')"
          >
          <input
          class="{{getControls('enabled').value?' bg-redbuttonremove text-redtextremove' : 'bg-greenactive text-textgre'}}  py-[4px] px-[24px] w-[140px] rounded-lg border border-buttongray cursor-pointer"
          type="button"
          value="{{getControls('enabled').value?'Vô hiệu hóa' : 'Kích hoạt'}}"
          (click)="changeStatus(getControls('enabled').value)">
    
        </div>
      </div>
    </app-modal>
  <app-toast
  id="notifiProfile"
  class=""
  statusToast="{{statusToast}}"
  MessToast="{{MessToast}}"
></app-toast>
</div>

